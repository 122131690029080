import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React, { ChangeEvent, useState } from 'react'
import { lawyersEarningFn, TotalEarningFn } from 'Services/Earnings'
import { MkxDivider, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableLoader,
  MkxTableRow,
} from 'Shared/MkxTable'

const Earning: React.FC = () => {
  const [datas, setDatas] = useState<string>(moment().format('YYYY-MM-DD'))
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState<string>('')

  const { data, isLoading } = useQuery({
    queryKey: ['getLawyerEarning', search, datas, page],
    queryFn: () => lawyersEarningFn({ search, page, date: moment(datas).format('YYYY-MM-DD') }),
    refetchOnWindowFocus: false,
  })
  const { data: totalData } = useQuery({
    queryKey: ['getTotalEarning', search, page, datas],
    queryFn: () => TotalEarningFn({ page, search, date: moment(datas).format('YYYY-MM-DD') }),
    refetchOnWindowFocus: false,
  })

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <div className="flex gap-4">
          <MkxInput
            placeholder="Search Contract Types..."
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
          <MkxInput
            type="date"
            value={datas}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setDatas(e.target.value)}
          />
        </div>

        <div className="pr-4 text-xl font-bold">
          Total Profite :{' '}
          <span className="text-green-600">
            ₹ {totalData?.total_deduction_from_likhit_de.toFixed(2)}
          </span>
        </div>
      </div>
      <MkxDivider />
      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Sr No</MkxTableCell>
              <MkxTableCell>{`Lawyer's Id`}</MkxTableCell>
              <MkxTableCell>Lawyer Name</MkxTableCell>
              <MkxTableCell>Email</MkxTableCell>
              <MkxTableCell>Total Pay</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            <MkxTableLoader loading={isLoading} row={5} />
            {data?.data?.map((contract_type: any, index: number) => (
              <MkxTableRow key={contract_type.id}>
                <MkxTableCell>{index + 1 + (page - 1) * 10}</MkxTableCell>
                <MkxTableCell>{contract_type.id}</MkxTableCell>
                <MkxTableCell className="capitalize ">{contract_type.name}</MkxTableCell>
                <MkxTableCell>{contract_type.email}</MkxTableCell>
                <MkxTableCell className="!font-semibold">
                  ₹{contract_type.total_pay ? contract_type.total_pay.toFixed(2) : 0}
                </MkxTableCell>
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>
        <MkxPagination data={data} setPage={setPage} />
      </MkxTableContainer>
    </div>
  )
}

export default Earning
