import { API_URLs } from 'Configs/apis.config' // Importing API URLs from configuration
import axios from 'Configs/axios.config' // Importing Axios instance

/**
 * Function to fetch news.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the fetched data.  **/

export const lawyerProfileVisitsFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.lawyer_profile_visits, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const lawyerServiceUsersFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.lawyer_service_user_list, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const totalLawyerFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.total_lawyer_api, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const totalActiveLawyerFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.total_active_lawyer, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const totalDeactiveLawyerFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.total_deactive_lawyer, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const totalLawyerAppointmentFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.lawyers_view_appointment, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const totalClientsFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.total_clients, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const lawyerAppointmentFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.lawyer_appointment, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
