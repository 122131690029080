import { AddCircle, Delete, Edit } from '@mui/icons-material'
import { useMutation, useQuery } from '@tanstack/react-query'
import ManageFAQ from 'Components/FAQ'
import { deleteFAQFn, getFAQFn } from 'Services/FAQ'
import { MkxButton, MkxDivider, MkxIconButton, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableLoader,
  MkxTableRow,
} from 'Shared/MkxTable'
import moment from 'moment'
import React, { useState } from 'react'
import toast from 'react-hot-toast'

const FAQ = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState<string>('')
  const [open, setOpen] = useState(false)
  const [questionId, setQuestionId] = useState<number>()
  const [isUpdate, setIsUpdate] = useState(false)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getAllBlogs', search, page],
    queryFn: () => getFAQFn({ page, search }),
    refetchOnWindowFocus: false,
  })

  const blogs = React.useMemo(() => {
    if (!data) return []
    return data.data
  }, [data])

  const { mutate: deleteBlogs } = useMutation({
    mutationFn: deleteFAQFn,
    onSuccess(res) {
      toast.success(res.message)
      refetch()
    },
  })

  return (
    <><div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Blogs..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <MkxButton startIcon={<AddCircle />} onClick={() => { setOpen(true); setIsUpdate(false) }}>
          New Faq
        </MkxButton>
      </div>
      <MkxDivider />
      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell className='!w-[20%]'>Question</MkxTableCell>
              <MkxTableCell >Answer</MkxTableCell>
              <MkxTableCell>Action</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            <MkxTableLoader loading={isLoading} row={2} />
            {blogs?.map((faq: any) => (
              <MkxTableRow key={faq.id}>
                <MkxTableCell>{faq.question}</MkxTableCell>
                <MkxTableCell>{faq.answer}</MkxTableCell>
                <MkxTableCell>
                  <span className="flex items-center gap-2">
                    <MkxIconButton
                      color="primary"
                      onClick={() => { setQuestionId(faq.id); setOpen(true); setIsUpdate(true) }}
                    >
                      <Edit />
                    </MkxIconButton>
                    <MkxIconButton color="error" onClick={() => deleteBlogs({ faq_id: faq.id })}>
                      <Delete />
                    </MkxIconButton>
                    {/* <MkxIconButton
                      color="success"
                      onClick={() => navigate(`/blog/${blog.id}/${blog.slug}`)}
                    >
                      <Visibility />
                    </MkxIconButton> */}
                  </span>
                </MkxTableCell>
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>
        <MkxPagination data={blogs} setPage={setPage} />
        <ManageFAQ questionId={questionId} setOpen={setOpen} open={open} fetch={refetch} setIsUpdate={setIsUpdate} isUpdate={isUpdate} />
      </MkxTableContainer>
    </div>

    </>
  )
}

export default FAQ
