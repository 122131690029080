import { Logout } from '@mui/icons-material'
import { Divider, List, ListItem, ListItemButton, useTheme } from '@mui/material'
import likhitde from 'Assets/egree.png'
import { menuItems } from 'Mock'
import { MkxDivider } from 'Shared/Mkx'
import { useLocalStorage } from 'Shared/MkxStorage'
import classNames from 'classnames'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
interface User {
  store_name: string
  user_name: string
}
const Sidebar: React.FC = () => {
  const [user] = useLocalStorage<User>('authentication', { store_name: '', user_name: '' })
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleLogout = () => {
    localStorage.clear()
    window.location.href = '/'
  }

  return (
    <div className="relative flex flex-col h-full bg-white dark:text-white rounded-b-lg dark:bg-[#121212] rounded-lg shadow w-72">
      <List className="!px-1 !flex !flex-col !gap-1">
        <ListItem className="flex items-center gap-2 h-fit">
          <img src={likhitde} loading="lazy" alt="" className="size-12" />
          <div className="flex flex-col">
            <p className="text-lg font-bold">{user?.store_name}</p>
            <p className="text-sm">Admin</p>
          </div>
        </ListItem>
        <Divider />
        <div className=" max-h-[81vh] overflow-y-scroll" style={{ scrollbarWidth: 'none' }}>
          {menuItems.map((item: any) => {
            const isActive = pathname.split('/')[1] === item?.link?.split('/')[1]
            return (
              <ListItemButton
                sx={{ background: isActive ? theme.palette.primary.main : '' }}
                key={item.id}
                className={classNames(
                  '!rounded !transition-all !duration-500',
                  isActive ? '!text-white !bg-primary' : ''
                )}
                onClick={() => navigate(item.link)}
              >
                <span className="flex items-center gap-4">
                  {item.icon} <p>{item.label}</p>
                </span>
              </ListItemButton>
            )
          })}
        </div>
      </List>
      <div className="!absolute !bottom-0  !bg-white w-full">
        <MkxDivider />
        <ListItemButton onClick={handleLogout}>
          <span className="flex items-center gap-4">
            <Logout /> <p>Logout</p>
          </span>
        </ListItemButton>
      </div>
    </div>
  )
}

export default Sidebar
