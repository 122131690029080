import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import classNames from 'classnames'

interface CustomButtonProps {
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
  className?: string
  children: React.ReactNode
  component?: React.ElementType
  hidden?: boolean
  variant?: 'contained' | 'outlined' | 'text'
  startIcon?: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  endIcon?: React.ReactNode
  isLoading?: boolean
  loadingContent?: React.ReactNode
}

const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  type = 'button',
  disabled,
  className = '',
  children,
  component, // remove the 'component' from here
  hidden,
  variant = 'contained',
  startIcon,
  size = 'medium',
  endIcon,
  isLoading = false,
  loadingContent = '',
  ...rest
}) => {
  return (
    <Button
      disableElevation
      type={type}
      size={size}
      onClick={onClick}
      hidden={hidden}
      disabled={isLoading || disabled}
      // Only include component prop if provided
      {...(component && { component: component })}
      variant={variant}
      startIcon={!isLoading && startIcon}
      endIcon={!isLoading && endIcon}
      className={classNames(
        'px-4 !capitalize',
        isLoading ? 'opacity-50 pointer-events-none' : '', // Add opacity and disable pointer events if loading
        className
      )}
      {...rest}
    >
      {isLoading ? (
        <span className="flex items-center gap-2">
          <CircularProgress size={20} thickness={5} />
          {loadingContent}
        </span>
      ) : (
        children
      )}
    </Button>
  )
}

export default CustomButton
