import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createContractTypeFn,
  getContractTypeFn,
  updateContractTypeFn,
} from 'Services/ContractType'
import withAuthentication from 'Shared/Authentication'
import { MkxButton, MkxEditor, MkxImagePicker, MkxInput, MkxPDFPicker } from 'Shared/Mkx'
import { ImagePicker } from 'Shared/useImageCrop/ImagePicker'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

const ManageContractType: React.FC = () => {
  const { state } = useLocation()
  const isUpdate = Boolean(state)
  const navigate = useNavigate()

  const { data } = useQuery({
    queryKey: ['getContractType', state],
    queryFn: () => getContractTypeFn({ contract_type_id: state }),
    refetchOnWindowFocus: false,
    enabled: isUpdate,
  })
  const contractType = data?.data

  const { mutate: createContractType, isPending: isCreating } = useMutation({
    mutationFn: createContractTypeFn,
    onSuccess(res) {
      // console.log(res)
      if (res) {
      toast.success(res?.message)
      navigate('/contract-type')
      } else {
        toast.error(res?.message)
      }
    },
  })
  const { mutate: updateContractType, isPending: isUpdating } = useMutation({
    mutationFn: updateContractTypeFn,
    onSuccess(res) {
      if (res.response_code === 200) {
        toast.success(res?.message)
        navigate('/contract-type')
      }
      else {
        toast.error(res?.message)
      }
    },
  })

  const initialValues = {
    title: contractType?.title ?? '',
    // meta_title: contractType?.meta_title ?? '',
    // meta_keyword: contractType?.meta_keyword ?? '',
    slug: contractType?.slug ?? '',
    contract_format: contractType?.contract_format ?? '',
    description: contractType?.description ?? '',
    images: '',
    content: contractType?.content ?? '',
    party1: contractType?.party1 ?? '',
    party2: contractType?.party2 ?? '',
    icon: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqBody = new FormData()
      reqBody.append('title', values.title)
      reqBody.append('party1', values.party1)
      reqBody.append('party2', values.party2)
      reqBody.append('slug', values.slug?.replaceAll(' ', '-')?.toLowerCase())
      reqBody.append('description', values.description)
      reqBody.append('contract_format', values.contract_format)
      reqBody.append('images', values.images)
      reqBody.append('content', values.content)
      reqBody.append('icon', values.icon)
      isUpdate && reqBody.append('contract_type_id', state)
      isUpdate ? updateContractType(reqBody) : createContractType(reqBody)
    },
  })
  useEffect(() => {
    formik.setFieldValue('slug', formik.values.title)
  }, [formik.values.title])

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5 p-5">
        <div className="grid grid-cols-3 gap-5">
          <MkxInput
            required
            id="title"
            name="title"
            label="Title"
            placeholder="Enter Title"
            value={formik.values.title}
            onChange={formik.handleChange}
          />

          <ImagePicker name="images" formik={formik} placeholder="Contract Type Image" />
          <MkxImagePicker name="icon" formik={formik} placeholder="Contract Type Icon" />

          <MkxInput
            name="party1"
            label="Party 1"
            placeholder="Enter party1 data"
            value={formik.values.party1}
            onChange={formik.handleChange}
          />
          <MkxInput
            name="party2"
            label="Party 2"
            placeholder="Enter party2 data"
            value={formik.values.party2}
            onChange={formik.handleChange}
          />
          {/* <MkxInput
            required
            name="slug"
            label="Slug"
            placeholder="Enter Slug"
            value={formik.values.slug?.replaceAll(' ', '-')?.toLowerCase()}
            onChange={formik.handleChange}
          /> */}
          {/* <MkxPDFPicker
            name="contract_format"
            formik={formik}
            placeholder="Contract Format"
          /> */}
        </div>
        {/* <MkxInputa
          rows={3}
          multiline
          name="meta_description"
          label="Meta Description"
          placeholder="Enter Meta Description"
          value={formik.values.meta_description}
          onChange={formik.handleChange}
        /> */}
        <MkxInput
          required
          rows={3}
          multiline
          name="description"
          label="Description"
          placeholder="Enter description"
          value={formik.values.description}
          onChange={formik.handleChange}
        />
        <div>
          <div className='flex gap-12 items-end'>
            <div className='font-semibold flex items-start whitespace-nowrap'>Make Contract Format<span className='pl-1'> *</span></div>
            <div className='flex flex-wrap gap-x-5 text-green-800 font-bold text-lg'>{data?.variables?.map((val: string, index: number) => (<span key={index}>{val && "{{ " + val + " }}"} </span>))}</div>
          </div>
          <MkxEditor formik={formik} />
        </div>

        <div className="flex items-center justify-end gap-5">
          <MkxButton color="inherit" onClick={() => navigate(-1)}>
            Cancel
          </MkxButton>
          <MkxButton loading={isCreating || isUpdating} type="submit">
            Submit
          </MkxButton>
        </div>
      </form>
    </>
  )
}

export default withAuthentication(ManageContractType)
