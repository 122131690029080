import { useMutation } from '@tanstack/react-query'
import likhitde from 'Assets/egree.png'
import { authenticateFn } from 'Services/Authentication'
import { MkxButton, MkxInput } from 'Shared/Mkx'
import { useLocalStorage } from 'Shared/MkxStorage'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const Authentication: React.FC = () => {
  const [auth, setAuth] = useLocalStorage<any>('authentication', {})
  const navigate = useNavigate()

  const { mutate: authenticate } = useMutation({
    mutationFn: authenticateFn,
    onSuccess: (res: any) => {
      toast.success(res.message)
      setAuth(res)
      navigate('/dashboard')
    },
  })

  const initialValues = {
    username: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => authenticate(values),
  })

  useEffect(() => {
    if (auth.token) {
      navigate('/dashboard')
    }
  }, [auth.token, navigate])

  return (
    <div className="flex w-full h-screen p-2 bg-gray-200">
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col items-center justify-center w-full h-full gap-5 bg-white border rounded-md shadow"
      >
        <img src={likhitde} alt="" className="size-32" />
        <p className="text-3xl font-semibold">Authentication</p>
        <MkxInput
          required
          type="username"
          name="username"
          label="Username"
          placeholder="xyz@likhitde.com"
          value={formik.values.username}
          onChange={formik.handleChange}
        />
        <MkxInput
          required
          type="password"
          name="password"
          label="Password"
          placeholder="••••••••••••"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <MkxButton type="submit">Sign In</MkxButton>
      </form>
    </div>
  )
}

export default Authentication
