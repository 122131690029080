import { Book, Category, Dashboard, Gavel, Newspaper, QuestionAnswer } from '@mui/icons-material'
import PeopleIcon from '@mui/icons-material/People'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ReportIcon from '@mui/icons-material/Report'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
/**
 * Interface for menu item.
 */
interface MenuItem {
  id: number // Unique ID for the menu item
  icon: JSX.Element // Icon component
  label: string // Label text for the menu item
  link: string // Link URL for the menu item
}

/**
 * Array of menu items.
 */
export const menuItems: MenuItem[] = [
  { id: 1, icon: <Dashboard />, label: 'Dashboard', link: '/dashboard' },
  { id: 2, icon: <Category />, label: 'Contract Type', link: '/contract-type' },
  { id: 3, icon: <Gavel />, label: 'Questionnaire', link: '/contract' },
  { id: 7, icon: <PeopleIcon />, label: 'Team', link: '/team' },
  { id: 4, icon: <Category />, label: 'Blog Category', link: '/blog-category' },
  { id: 5, icon: <Book />, label: 'Blog', link: '/blog' },
  { id: 6, icon: <Newspaper />, label: 'News', link: '/news' },
  { id: 8, icon: <PeopleIcon />, label: 'Lawyer', link: '/lawyer-list' },
  { id: 9, icon: <Newspaper />, label: 'NFC Card', link: '/nfc-card' },
  { id: 10, icon: <Category />, label: 'Plan Type', link: '/planType' },
  { id: 11, icon: <Category />, label: 'Plans', link: '/subscription-plan' },
  { id: 11, icon: <ConfirmationNumberIcon />, label: 'Coupons', link: '/coupons' },
  { id: 13, icon: <ReceiptIcon />, label: 'Earnings', link: '/earning' },
  {
    id: 14,
    icon: <FactCheckIcon />,
    label: 'Direct Payment Distribution',
    link: '/direct-payment-distibution',
  },
  {
    id: 15,
    icon: <FactCheckIcon />,
    label: 'Appointment Distribution',
    link: '/appointment-distibution',
  },
  {
    id: 16,
    icon: <QuestionAnswer />,
    label: 'FAQ',
    link: '/faq',
  },
  { id: 17, icon: <ReportIcon />, label: 'Report', link: '/report' },
]
