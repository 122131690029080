import { useQuery } from '@tanstack/react-query';
import { imageFormat } from 'Configs/axios.config';
import { getContractTypeFn } from 'Services/ContractType';
import withAuthentication from 'Shared/Authentication';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useParams } from 'react-router-dom';

const ViewContractType: React.FC = () => {
  const { contract_type_id } = useParams();
  const [pdfError, setPdfError] = useState<string | null>(null);

  const { data, error, isLoading } = useQuery({
    queryKey: ['getContractTypeDetail', contract_type_id],
    queryFn: () => getContractTypeFn({ contract_type_id }),
    refetchOnWindowFocus: false,
  });

  const contractType = data?.data;

  return (  
    <div className="flex flex-col p-4">
      <img src={imageFormat(contractType?.images)} alt="Contract Type" className="w-1/4 mx-auto" />
      <div className="flex flex-col gap-4 p-5">
        <p className="text-3xl font-bold text-center">{contractType?.title}</p>
        <p className="text-base px-[10%] font-medium text-center">{contractType?.description}</p>
        <div dangerouslySetInnerHTML={{ __html: contractType?.content }} className="px-[10%]"></div>
        <div className="px-[10%]">
          {contractType?.contract_format ? (
            <>
              <object className="w-full"
                // data={imageFormat(contractType?.contract_format)}
                data={"https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210101201653/PDF.pdf"}
                width="500"
                height="1000">
              </object>
            </>
          ) : (
            <p>No PDF available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default withAuthentication(ViewContractType);
