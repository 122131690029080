import { useMutation, useQuery } from '@tanstack/react-query'
import { teamCreateFn, teamGetFn, teamUpdateFn } from 'Services/Team'
import { MkxButton, MkxInput } from 'Shared/Mkx'
import { ImagePicker } from 'Shared/useImageCrop/ImagePicker'
import { useFormik } from 'formik'
import React from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

const ManageTeam: React.FC = () => {
  const { state } = useLocation()
  const isUpdate = Boolean(state)
  const navigate = useNavigate()
  // const { data: blogCategories } = useQuery({
  //   queryKey: ['getBlogCategories'],
  //   queryFn: getBlogCategoriesFn,
  //   refetchOnWindowFocus: false,
  // })

  const { data } = useQuery({
    queryKey: ['teamGet', state],
    queryFn: () => teamGetFn({ team_id: state }),
    refetchOnWindowFocus: false,
    enabled: isUpdate,
  })
  const blog = data?.data[0]
  const { mutate: teamCreate, isPending: isCreating } = useMutation({
    mutationFn: teamCreateFn,
    onSuccess(res) {
      toast.success(res.message)
      navigate('/team')
    },
  })
  const { mutate: teamUpdate, isPending: isUpdating } = useMutation({
    mutationFn: teamUpdateFn,
    onSuccess(res) {
      toast.success(res.message)
      navigate('/team')
    },
  })

  const initialValues = {
    name: blog?.name ?? '',
    role: blog?.role ?? '',
    description: blog?.description ?? '',
    // content: blog?.content ?? 'Write your blog content here..',
    image: '',
    // category_id: blog?.category_id ?? '',
  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit(values) {
      const reqBody = new FormData()
      reqBody.append('name', values.name)
      reqBody.append('role', values.role)
      reqBody.append('image', values.image)
      reqBody.append('description', values.description)
      isUpdate && reqBody.append('team_id', state)
      isUpdate ? teamUpdate(reqBody) : teamCreate(reqBody)
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5 p-3">
        <div className="grid grid-cols-1 gap-5">
          <MkxInput
            required
            id="name"
            name="name"
            label="Name"
            placeholder="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <ImagePicker name="image" formik={formik} placeholder="Team Member Image" />

          <MkxInput
            name="role"
            label="Role"
            placeholder="Enter Role"
            value={formik.values.role}
            onChange={formik.handleChange}
          />
        </div>
        <MkxInput
          rows={3}
          multiline
          name="description"
          label="description"
          placeholder="Enter  Description"
          value={formik.values.description}
          onChange={formik.handleChange}
        />
        {/* <MkxEditor formik={formik} /> */}

        <div className="flex items-center justify-end gap-5">
          <MkxButton color="inherit" onClick={() => navigate(-1)}>
            Cancel
          </MkxButton>
          <MkxButton loading={isCreating || isUpdating} type="submit">
            Submit
          </MkxButton>
        </div>
      </form>
    </>
  )
}

export default ManageTeam
