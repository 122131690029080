import { Edit } from '@mui/icons-material'
import { Box, Button, Divider, Modal } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React from 'react'
import toast from 'react-hot-toast'
import { deleteContractTypeFn } from 'Services/ContractType'
import { getdistributionFn, updateCommissionFn } from 'Services/DistributionAmount'
import CustomInput from 'Shared/CustomInput'
import { MkxButton, MkxDivider, MkxIconButton, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableLoader,
  MkxTableRow,
} from 'Shared/MkxTable'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const AmountDistribution: React.FC = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState<string>('')
  const [openDetails, setOpenDetails] = React.useState(false)
  const [openCommition, setOpenCommition] = React.useState(false)
  const [commitionData, setCommitionData] = React.useState<any>()
  const [paymentId, setPaymentId] = React.useState<any>()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getDistribution'],
    queryFn: getdistributionFn,
    refetchOnWindowFocus: false,
  })
  const { data: paymentsData, refetch: refetchData } = useQuery({
    queryKey: ['distributionDetails', paymentId],
    queryFn: () => getdistributionFn({ payment_id: paymentId }),
    refetchOnWindowFocus: false,
  })
  console.log('Payment Id : ', paymentId)

  const dataDetails = paymentsData?.data
  console.log('Response : ', paymentsData)

  const { mutate: updateCommission } = useMutation({
    mutationFn: updateCommissionFn,
    onSuccess(res) {
      toast.success(res.message)
      refetch()
    },
  })

  const reqBody = new FormData()
  reqBody.append('payment_id', paymentId)
  reqBody.append('likhitde_commission', commitionData)
  const updateCommition = () => {
    updateCommission(reqBody)
    setOpenCommition(false)
  }
  const handleUpdates = (data: any, id: any) => {
    setCommitionData(data)
    setOpenCommition(true)
    setPaymentId(id)
  }
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Lawyers..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        {/* 
        <MkxButton startIcon={<AddCircle />} onClick={() => navigate('/contract-type/create')}>
          New Contract Type
        </MkxButton> */}
      </div>
      <MkxDivider />
      <MkxTableContainer className="!overflow-x-scroll">
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Sr No</MkxTableCell>
              <MkxTableCell>Date </MkxTableCell>
              <MkxTableCell>Payment ID</MkxTableCell>
              <MkxTableCell>Lawyer Name</MkxTableCell>
              <MkxTableCell>Likhit Commission</MkxTableCell>
              <MkxTableCell>Transaction ID</MkxTableCell>
              <MkxTableCell>Transaction Amount</MkxTableCell>
              <MkxTableCell>Lawyer Payable Amount </MkxTableCell>
              <MkxTableCell>Action</MkxTableCell>
              {/* <MkxTableCell>Gateway Charges</MkxTableCell>
             
              <MkxTableCell>Razorpay Amt</MkxTableCell>
              <MkxTableCell>Razorpay GST</MkxTableCell>
              <MkxTableCell>Razorpay Total Amt</MkxTableCell>
              <MkxTableCell>Gross Likhit Amt</MkxTableCell>
              <MkxTableCell>Likhit GST</MkxTableCell>
              <MkxTableCell>Total Likhit Amt</MkxTableCell>
              <MkxTableCell>Split Amt</MkxTableCell>
              <MkxTableCell>Split GST</MkxTableCell>
              <MkxTableCell>Total Split Amt</MkxTableCell>
              <MkxTableCell>Net Likhit</MkxTableCell>
              <MkxTableCell>Lawyer</MkxTableCell> */}
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            <MkxTableLoader loading={isLoading} row={13} />
            {data?.data?.map((contract_type: any, index: number) => (
              <MkxTableRow key={contract_type.id}>
                <MkxTableCell>{index + 1}</MkxTableCell>
                <MkxTableCell>{moment(contract_type.created_at).format('DD/MM/yyyy')}</MkxTableCell>
                <MkxTableCell>{contract_type.id}</MkxTableCell>
                <MkxTableCell>{contract_type.lawyer.name}</MkxTableCell>
                <MkxTableCell className="!flex gap-5 !justify-evenly">
                  {contract_type.likhit_de_commission} %
                  <MkxIconButton
                    color="primary"
                    onClick={() =>
                      handleUpdates(contract_type.likhit_de_commission, contract_type.id)
                    }
                  >
                    <Edit />
                  </MkxIconButton>
                </MkxTableCell>
                <MkxTableCell>{contract_type.payment_no}</MkxTableCell>
                <MkxTableCell>{contract_type.payment_amount}</MkxTableCell>
                <MkxTableCell>
                  ₹ {contract_type.payable_amount_to_lawyer_after_charge.toFixed(2)}
                </MkxTableCell>
                <MkxTableCell>
                  {' '}
                  <MkxButton
                    variant="text"
                    className="!bg-gray-200"
                    color="info"
                    onClick={() => {
                      setOpenDetails(true)
                      setPaymentId(contract_type.id)
                      refetchData()
                    }}
                  >
                    view
                  </MkxButton>{' '}
                </MkxTableCell>
                {/* <MkxTableCell>{contract_type.gateway_commission}%</MkxTableCell>

                <MkxTableCell>{contract_type.getway_amt.toFixed(2)}</MkxTableCell>
                <MkxTableCell>{contract_type.getway_gst_amt.toFixed(2)}</MkxTableCell>
                <MkxTableCell>{contract_type.getway_total_amt_gst.toFixed(2)}</MkxTableCell>
                <MkxTableCell>{contract_type.likhit_de_net_amt.toFixed(2)}</MkxTableCell>
                <MkxTableCell>{contract_type.likhit_gst_amt.toFixed(2)}</MkxTableCell>
                <MkxTableCell>{contract_type.likhit_total_amt_gst.toFixed(2)}</MkxTableCell>
                <MkxTableCell>{contract_type.split_amt.toFixed(2)}</MkxTableCell>
                <MkxTableCell>{contract_type.split_gst_amt.toFixed(2)}</MkxTableCell>
                <MkxTableCell>{contract_type.split_total_amt_gst.toFixed(2)}</MkxTableCell>
                <MkxTableCell>
                  {contract_type.total_deduction_from_likhit_de.toFixed(2)}
                </MkxTableCell>
                <MkxTableCell>
                  {contract_type.payable_amount_to_lawyer_after_charge.toFixed(2)}
                </MkxTableCell> */}
                {/* <MkxTableCell>
                  <span className="flex items-center gap-2">
                    <MkxIconButton
                      color="primary"
                      onClick={() => navigate('/contract-type/update', { state: contract_type.id })}
                    >
                      <Edit />
                    </MkxIconButton>
                    <MkxIconButton
                      color="error"
                      onClick={() => deleteContractType({ contract_type_id: contract_type.id })}
                    >
                      <Delete />
                    </MkxIconButton>
                    <MkxIconButton
                      color="success"
                      onClick={() =>
                        navigate(`/contract-type/${contract_type.id}/${contract_type.slug}`)
                      }
                    >
                      <Visibility />
                    </MkxIconButton>
                  </span>
                </MkxTableCell> */}
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>
        <MkxPagination data={data} setPage={setPage} />
      </MkxTableContainer>

      <Modal
        keepMounted
        open={openCommition}
        onClose={() => setOpenCommition(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <div className="absolute top-[30%] left-[35%] bg-white py-4">
          <div className="flex justify-between items-center px-4 pb-4">
            <CustomInput
              id="cummitions"
              label="Update Cummition"
              value={commitionData}
              onChange={(e: any) => setCommitionData(e.target.value)}
            ></CustomInput>
          </div>
          <div className="flex justify-end gap-2 pr-4">
            <MkxButton
              variant="contained"
              size="small"
              onClick={() => setOpenCommition(false)}
              className="!px-6 !ml-4"
              // isLoading={isLoadingUpdate}
              // loadingContent="Applying.."
            >
              Cancel
            </MkxButton>
            <MkxButton
              variant="contained"
              size="small"
              type="submit"
              onClick={updateCommition}
              className="!px-6 !ml-4"
              // isLoading={isLoadingUpdate}
              // loadingContent="Applying.."
            >
              Submit
            </MkxButton>
          </div>
        </div>
      </Modal>
      {/* View Details */}
      <Modal
        keepMounted
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <div>
          <Box sx={style} className="!w-[90%] lg:!w-[45%]">
            <div className="flex justify-between items-center px-4 pb-4">
              <div></div>
              <div className="font-bold text-2xl ">Lawyer Amount Distribution</div>
              <div
                className=" text-red-500 text-3xl font-bold hover:cursor-pointer"
                onClick={() => setOpenDetails(false)}
              >
                x
              </div>
            </div>
            <Divider className="!border-1 !border-gray-700 !mt-3 " />

            <div className=" !max-h-[70vh] overflow-y-scroll hide-scroll py-3 font-medium">
              <div className="grid grid-cols-1 pb-3">
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Date </div>
                  <div>{moment(dataDetails?.created_at).format('DD/MM/yyyy')} </div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Payment Id </div>
                  <div> {dataDetails?.id}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Name </div>
                  <div>{dataDetails?.lawyer?.name}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Likhit Commission </div>
                  <div>{dataDetails?.likhit_de_commission}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Transaction No </div>
                  <div> {dataDetails?.payment_no}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Transaction Amount </div>
                  <div>{dataDetails?.payment_amount}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Payment Methods </div>
                  <div>{dataDetails?.payment_method}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Gateway Charges </div>
                  <div>{dataDetails?.gateway_commission}%</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Razorpay Amt </div>
                  <div>{dataDetails?.getway_amt?.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Razorpay GST </div>
                  <div>{dataDetails?.getway_gst_amt?.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Razorpay Total Amt</div>
                  <div>{dataDetails?.getway_total_amt_gst?.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Gross Likhit Amt</div>
                  <div>{dataDetails?.likhit_de_net_amt?.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Likhit GST </div>
                  <div>{dataDetails?.likhit_gst_amt?.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Total Likhit Amt</div>
                  <div>{dataDetails?.likhit_total_amt_gst?.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Split Amt </div>
                  <div>{dataDetails?.split_amt?.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Split GST </div>
                  <div>{dataDetails?.split_gst_amt?.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Total Split Amt</div>
                  <div>{dataDetails?.split_total_amt_gst?.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Net Likhit </div>
                  <div>{dataDetails?.total_deduction_from_likhit_de?.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-2  gap-3 pb-3">
                  <div className="pl-5">Payable to Lawyaer </div>
                  <div>{dataDetails?.payable_amount_to_lawyer_after_charge?.toFixed(2)}</div>
                </div>
              </div>
            </div>
            <Divider className="!border-1 !border-gray-700 !mt-3 " />

            <div className="flex justify-end py-4">
              <Button
                variant="contained"
                size="medium"
                type="submit"
                onClick={() => setOpenDetails(false)}
                className="!px-6 !ml-4"
                // isLoading={isLoadingUpdate}
                // loadingContent="Applying.."
              >
                Cancel
              </Button>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  )
}

export default AmountDistribution
