export const API_URLs = {
  authentication: 'blog-admin-login-api/',
  dashboard: 'dashboard-api-of-admin/',
  blogCategories: 'blog-category-api-of-admin/',
  blogs: 'blog-api-of-admin/',
  blogCategory: 'blog-category-api-of-admin/',
  contractType: 'contract-type-api-of-admin/',
  news: 'news-api-of-admin/',
  lawyer_list: 'list-lawyer-admin-api/',
  lawyer_profile: 'lawyer-details-admin-api/',
  lawyer_kyc_details: 'lawyer-kyc-document-admin-api/',
  update_lawyer: 'update-lawyer-Admin-api/',
  delete_lawyer: 'delete-lawyer-admin-api/',
  update_status: 'lawyer-activate-deactivate/',
  team_add_update_del: 'add-edit-team-admin-api/',
  get_subscription_plan: 'get-subscription-plans-admin-api/',
  crud_plan_type: 'crud-plan-admin-api/',
  crud_Plans: 'crud-plan-price-admin-api/',
  get_transaction: 'transecation-history-admin-api/',
  lawyer_profile_visits: 'count-lawyer-profile-visitor-admin-api/',
  lawyer_service_user_list: 'lawyer-service-user-admin-api/',
  lawyer_appointment: 'lawyer-view-appointment-admin-api/',
  total_lawyer_api: 'total-lawyer-admin-api/',
  lawyers_view_appointment: 'lawyer-view-appointment-admin-api/',
  total_active_lawyer: 'avtive-lawyer-admin-api/',
  total_deactive_lawyer: 'deactive-lawyer-admin-api/',
  total_clients: 'total-client-admin-api/',
  amount_distribution: 'direct-earning-amt-list-and-update-admin-api/',
  lawyer_earning: 'total-earning-amt-single-lawyer-admin-api/',
  total_earing: 'total-earning-amt-admin-api/',
  add_coupon: 'add-coupon-admin-api/',
  coupon_list_oprations: 'coupon-list-update-delete-admin-api/',
  coupon_enable: 'coupon-enable-or-disable-admin-api/',
  nfc_card_management: 'ncfcard-management-admin-api/',
  appointment_distribution: 'appointment-earning-amt-list-and-update-admin-api/',
  view_profile: 'view-profile-api/',
  update_profile: 'update-profile-lawyer-api/',
  client_get_profile: 'get-update-profile-client-api/',
  fee_add: 'crud-lawyer-fee-api/',
  education_crud: 'crud-lawyer-education-api/',
  review_list: 'reviews-list-for-lawyer-api/',
  write_review: 'reviews-api/',
  experience_crud: 'crud-lawyer-experience-api/',
  like: `review-like-api/`,
  dislike: `review-dislike-api/`,
  book_appointment: `user-book-appointment-api/ `,
  payment_services: 'pay-laywer-for-service-api/',
  bar_associations_get: 'get-court-association-id-api/',
  social_media_crud: 'crud-lawyer-social-media/',
  bank_details: 'crud-lawyer-bank/',
  contractTypes: 'contract-type-api-of-web/',
  login: 'get-otp-api/',
  verify_otp: 'verify-user-otp-api/',
  user_type: 'confirm-user-api/',
  logout: 'logout-api/',
  lawyers_Details: 'lawyer-details-api/',
  numbers_add: 'crud-lawyer-phone-number-api/',
  email_crud: 'crud-lawyer-email-api/',
  oprating_hours: 'crud-lawyer-operating-hours-api/',
  team_list: 'team-list-api/',
  get_nfc_card_plan: `get-nfc-card-plans-view/`,
  lawyer_approval: 'lawyer-approval-api/',
  Questionair: 'contract-crud-questions-api-of-admin/',
  faq: 'faq-admin-api/',
}
