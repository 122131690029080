import { Chip, Divider, Paper, Popper } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { lawyerApproval } from 'Services/LawyerList'
import CustomButton from 'Shared/CustomButton'
import Loader from 'Shared/Loader'
import { useState } from 'react'
import toast from 'react-hot-toast'

const ManageStatus = ({
  refetch,
  status,
  id,
}: {
  refetch: () => void
  status: string
  id: number
}) => {
  const [openRes, setOpenRes] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const { mutate: statusUpdate, isPending: isLoadingStatus } = useMutation({
    mutationFn: lawyerApproval,
    onSuccess: (response: any) => {
      console.log('Status : ', response)
      if (response.response_code === 200) {
        toast.success(response.message)
        setOpenRes(false)
        refetch()
      } else {
        toast.error(response.message)
      }
    },
  })
  const statusChange = (name: string) => {
    statusUpdate({ status: name, lawyer_id: id })
  }
  return isLoadingStatus ? (
    <Loader />
  ) : (
    <div>
      <div>
        <Chip
          label={status}
          className="w-20 lg:w-28"
          clickable={status === 'Approved' ? false : true}
          color={status === 'Pending' ? 'primary' : status === 'Approved' ? 'success' : 'error'}
          onClick={(event: any) => {
            if (status !== 'Approved') {
              setAnchorEl(event.currentTarget)
              setIsOpen(!isOpen)
            }
          }}
        />

        <Popper
          placement="top"
          sx={{ zIndex: 1200 }}
          anchorEl={anchorEl}
          open={isOpen}
          className="pb-1"
        >
          <Paper className="flex flex-col justify-center !bg-slate-300 !shadow-xl">
            <p className="px-3 text-lg text-center">Lawyer Approval</p>
            <Divider />
            <div className="flex justify-center gap-2 p-2">
              <CustomButton
                className="!rounded-md !bg-green-600"
                isLoading={isLoadingStatus}
                onClick={() => {
                  setAnchorEl(null)
                  setIsOpen(false)
                  statusChange('Approved')
                }}
              >
                Approved
              </CustomButton>
              {status !== 'Reject' && (
                <CustomButton
                  className="!rounded-md !bg-red-700"
                  isLoading={isLoadingStatus}
                  onClick={() => {
                    setAnchorEl(null)
                    setIsOpen(false)
                    setOpenRes(true)
                    statusChange('Rejected')
                  }}
                >
                  Rejected
                </CustomButton>
              )}
            </div>
          </Paper>
        </Popper>
      </div>
    </div>
  )
}

export default ManageStatus
