import BadgeIcon from '@mui/icons-material/Badge'
import BusinessIcon from '@mui/icons-material/Business'
import SchoolIcon from '@mui/icons-material/School'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import { Rating, Tab, Tabs } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
// import Details from 'Assets/Depth 4, Frame 0.png'
import logo from 'Assets/egree.png'
import { lawyerProfilesFn } from 'Services/LawyerDetails'
import moment from 'moment'
import { SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const LawyersDetails = () => {
  const { id } = useParams()
  const [viewProfile, setViewProfile] = useState<any>([])
  const [value, setValue] = useState(0)
  const handleChange = (_: any, newValue: SetStateAction<number>) => {
    setValue(newValue)
  }
  const { data } = useQuery({
    queryKey: ['viewDetails', id],
    queryFn: () => lawyerProfilesFn({ lawyer_id: id }),
  })

  useEffect(() => {
    setViewProfile(data?.data)
  }, [data?.data])
  console.log(viewProfile)

  return (
    <div className="py-[4%] px-3 sm:px-[7%] sm:pr-0">
      {/* <div className="fixed bottom-32 right-32 p-3 text-lg bg-gray-300 rounded-md ">
        <AddIcon />
      </div> */}

      <div className="flex flex-col  lg:flex-row lg:justify-between md:pr-[20%]">
        {/* Profile Logo Section */}
        <div className="flex gap-6 sm:px-6 sm:py-3 w-[60%]">
          <img alt="logo" src={viewProfile?.image} className="h-32 w-32 rounded-full" />
          <div className="flex flex-col w-[70%] ">
            <div className="text-sm sm:text-md font-bold py-1 sm:py-3 sm:pb-1">
              {moment(viewProfile?.dob).calendar()}
            </div>
            <div className="text-lg sm:text-xl capitalize font-bold">{viewProfile?.name}</div>
            <div className="text-sm sm:text-base font-extralight text-gray-500 pb-3 sm:pb-1">
              {viewProfile?.address}
            </div>
            {/* <div className="text-xs sm:text-sm ms-[6%] sm:mx-[2%] font-bold">
              Book Appointment Block
            </div> */}
          </div>
        </div>

        {/* Buttons Section */}
        <div className="flex flex-col gap-2  py-2">
          <div className="flex justify-around md:gap-10  my-0 py-2 ">
            {/* <MkxButton className=" whitespace-nowrap !w-40 !lg:min-w-52" autoFocus type="submit">
              Pay Now
            </MkxButton> */}
          </div>
          <div className="flex justify-around md:gap-10 my-0 py-2 ">
            {/* <MkxButton className=" whitespace-nowrap !w-40 !lg:min-w-52" autoFocus type="submit">
              Save Contact
            </MkxButton>
            <MkxButton
              className=" whitespace-nowrap !w-40 !lg:min-w-52"
              color="inherit"
              aria-label="close"
              onClick={handleOpen}
            >
              Edit Profile
            </MkxButton> */}
          </div>
        </div>
      </div>

      {/* About Section */}
      <div className="flex gap-4 sm:px-6 sm:py-2 w-full mt-2 justify-between">
        <div className="flex flex-col gap-3 w-11/12 ">
          <div className="font-bold text-xl capitalize sm:text-2xl">
            {' '}
            About adv {viewProfile?.name}
          </div>
          <div className="text-sm sm:text-lg">
            {viewProfile?.about} I am a civil rights lawyer. I have been practicing law for over 10
            years. I have experience in representing clients in federal and state.
          </div>
        </div>
        {/* <img
          alt="details"
          src={Details}
          className="object-contain mt-[-2%] flex lg:hidden ml-[-15%]"
        /> */}
      </div>

      {/* Service Offers Section */}
      <div className="sm:px-6 sm:py-2 ">
        <div className="flex gap-7  my-4 justify-between">
          <div className="font-bold text-xl sm:text-2xl"> Services offered</div>
          {/* <img
            alt="details"
            // onClick={() => setOpenSpeciality(true)}
            src={Details}
            className="object-contain mt-[-2%] flex "
          /> */}
        </div>
        {/* Service Offer Buttons Section  FOr Web*/}
        {/* <div className="hidden lg:flex gap-7 p-6 lg:p-0 my-4 justify-between">
          <div className="flex  gap-3 w-11/12 sm:w-2/3">
            <div className="w-1/3 whitespace-nowrap rounded-md font-medium text-xs lg:w-40 sm:text-sm flex justify-center items-center  bg-gray-200 p-2 leading-none">
              <span>Legal Advice </span>{' '}
              <span>
                <KeyboardArrowDownIcon />
              </span>
            </div>

            <div className="rounded-md whitespace-nowrap font-medium text-xs sm:text-sm flex lg:w-40 justify-center items-center w-1/3 bg-gray-200 p-2 leading-none">
              <span>Legal Advice </span>{' '}
              <span>
                <KeyboardArrowDownIcon />
              </span>
            </div>

            <div className="rounded-md whitespace-nowrap font-medium text-xs sm:text-sm flex lg:w-40 justify-center items-center w-1/3 bg-gray-200 p-2 leading-none">
              <span>Legal Advice </span>{' '}
              <span>
                <KeyboardArrowDownIcon />
              </span>
            </div>
          </div>

        </div> */}
        {viewProfile?.fees?.map((items: any, index: number) => (
          <div key={index} className="flex gap-7 w-full p-1 py-1 lg:pr-6 lg:py-2 justify-between">
            <div className="flex gap-2 sm:gap-8 w-11/12 sm:w-9/12 justify-between items-center">
              <div className="flex gap-4 sm:gap-8 lg:w-1/2 xl:w-[45%] justify-between items-center">
                <div className="bg-gray-200 rounded-md w-16 h-12 flex justify-center items-center">
                  {/* <SearchIcon /> */}
                  <img alt="icons" src={logo} />
                </div>
                <div className="flex flex-col w-4/5 ">
                  <div className="font-medium text-base sm:text-lg">{items.title}</div>
                  <div className="text-sm sm:text-base font-light text-gray-500">
                    {items.subtitle}
                  </div>
                </div>
                <div className="font-medium text-sm sm:text-lg whitespace-nowrap">Starting at</div>
              </div>
              <div className="font-medium text-sm sm:text-lg">{items.fee}</div>
            </div>
            {/* <img alt="details" src={Details} className="object-contain mt-[-2%] flex lg:hidden" /> */}
          </div>
        ))}
      </div>

      {/* Service Offers Buttons Section For Mobile */}
      {/* <div className=" lg:hidden sm:px-6 sm:py-2 ">
        <div className="font-bold text-xl sm:text-2xl"> Services Offered</div>
        <div className="flex gap-7 px-6 py-2 lg:py-6 my-2 justify-between">
          <div className="flex  gap-3 w-11/12 sm:w-2/3 flex-wrap">
            {viewProfile?.fees?.map((items: any, index: number) => (
              <div
                key={items.id}
                className="w-5/12 lg:w-48 whitespace-nowrap rounded-md font-medium text-xs sm:text-sm flex justify-center items-center  bg-gray-200 pr-2 leading-none"
              >
                <span>{items.title} </span>{' '}
                <span>
                  <KeyboardArrowDownIcon />
                </span>
              </div>
            ))}
          </div>
          <img alt="details" src={Details} className="object-contain mt-[-2%] flex lg:hidden" />
        </div>
      </div> */}

      {/* Speciality Section For Web */}
      <div className="flex flex-col sm:px-6 sm:py-2 ">
        <div className="font-bold text-xl sm:text-2xl"> Specialities</div>

        <div className="flex gap-7  my-4 justify-between">
          <div className="flex  gap-5 w-11/12   flex-wrap">
            {viewProfile?.specialties?.map((items: any, index: number) => (
              <div
                key={index}
                className="w-1/3 lg:w-48 whitespace-nowrap rounded-md font-medium text-xs sm:text-sm flex justify-center items-center  bg-gray-200 p-2 leading-none"
              >
                <span>{items}</span>{' '}
              </div>
            ))}
          </div>
          {/* <img alt="details" src={Details} className="object-contain mt-[-2%] flex lg:hidden" /> */}
        </div>
      </div>

      {/* Experience Section For Web */}
      <div className="hidden lg:flex  gap-6 sm:px-6 sm:py-2 w-fulljustify-between">
        <div className="flex flex-col gap-3 w-11/12 ">
          <div className="font-bold text-xl sm:text-2xl">Experience</div>
          <div className="text-sm sm:text-lg">{viewProfile?.experience}</div>
        </div>
        {/* <img alt="details" src={Details} className="object-contain mt-[-2%] flex lg:hidden " /> */}
      </div>

      {/* Education Section */}
      <div className="sm:px-6 sm:py-2 ">
        <div className="flex gap-7  my-4 justify-between">
          {/* <div className="font-bold text-xl sm:text-2xl"> Services offered</div> */}
          <div className="font-bold text-xl sm:text-2xl">Education & Credencials</div>
          {/* <img
            alt="details"
            // onClick={() => setOpenEducation(true)}
            src={Details}
            className="object-contain mt-[-2%] flex "
          /> */}
        </div>
        <div className="flex justify-between">
          <div className="w-full">
            {viewProfile?.education_credentials?.map((items: any, index: number) => (
              <div key={index} className="flex gap-7 p-1 py-4  lg:py:-2 justify-between">
                <div className="flex gap-8 w-11/12 lg:w-1/2 xl:w-1/3 justify-between items-center">
                  <div className="bg-gray-200 rounded-md w-16 h-12 flex justify-center items-center">
                    <SchoolIcon />
                  </div>
                  <div className="flex flex-col w-2/3 ">
                    <div className="font-medium text-base sm:text-lg">{items.name}</div>
                    <div className="text-sm sm:text-base font-light text-gray-500">
                      {items.title}
                    </div>
                  </div>
                  <div className="font-medium text-base sm:text-lg">{items.year.slice(0, 4)}</div>
                </div>
              </div>
            ))}

            <div className="flex gap-7 p-1 py-4  lg:py:-2  justify-between">
              <div className="flex gap-8 w-11/12 lg:w-1/2 xl:w-1/3 justify-between items-center">
                <div className="bg-gray-200 rounded-md w-16 h-12 flex justify-center items-center">
                  <SchoolIcon />
                </div>
                <div className="flex flex-col w-2/3">
                  <div className="font-medium text-base sm:text-lg">UCLA School of Law</div>
                  <div className="text-sm sm:text-base font-light text-gray-500">
                    Have your legal documents reviewed by an expert.
                  </div>
                </div>
                <div className="font-medium text-base sm:text-lg">2010</div>
              </div>
            </div>

            <div className="flex gap-7 p-1 py-4  lg:py:-2   justify-between">
              <div className="flex gap-8 w-11/12 lg:w-1/2 xl:w-1/3 justify-between items-center">
                <div className="bg-gray-200 rounded-md w-16 h-12 flex justify-center items-center">
                  <SignalCellularAltIcon />
                </div>
                <div className="flex flex-col w-2/3">
                  <div className="font-medium text-base sm:text-lg">Maharashtra State Bar</div>
                  <div className="text-sm sm:text-base font-light text-gray-500">License</div>
                </div>
                <div className="font-medium text-base sm:text-lg">Active</div>
              </div>
            </div>
          </div>
          {/* <img alt="details" src={Details} className="object-contain mt-[-2%] flex lg:hidden" /> */}
        </div>
      </div>

      {/* Experience  Section  */}
      <div className="sm:px-6 sm:py-2 ">
        <div className="flex gap-7  my-4 justify-between">
          {/* <div className="font-bold text-xl sm:text-2xl"> Services offered</div> */}
          <div className="font-bold text-xl sm:text-2xl">Experience</div>
          {/* <img
            alt="details"
            // onClick={() => setOpenExperience(true)}
            src={Details}
            className="object-contain mt-[-2%] flex "
          /> */}
        </div>

        <div className="flex justify-between">
          <div className="w-full">
            {viewProfile?.experiences?.map((items: any, index: number) => (
              <div key={index} className=" flex gap-7 p-1 py-4 lg:py-2  justify-between">
                <div className="flex gap-8  w-11/12 lg:w-2/3 justify-between lg:justify-normal items-center">
                  <div className="bg-gray-200 rounded-md w-16 h-12 flex justify-center items-center">
                    <StarBorderIcon />
                  </div>
                  <div className="flex flex-col w-2/3 ">
                    <div className="font-medium text-base sm:text-lg">{items?.title}</div>
                    <div className="text-sm sm:text-base font-light text-gray-500">
                      {items?.subtitle}
                    </div>
                    <div className="hidden lg:flex text-sm sm:text-base font-light text-gray-500">
                      {moment(items?.from_date).year()} -
                      {moment(items?.to_date).year() === 2024
                        ? 'Present'
                        : moment(items?.to_date).year()}
                    </div>
                  </div>
                  <div className="lg:hidden font-medium text-base whitespace-nowrap sm:text-lg">
                    {moment(items?.from_date).year()} -
                    {moment(items?.to_date).year() === 2024
                      ? 'Present'
                      : moment(items?.to_date).year()}
                  </div>
                </div>
              </div>
            ))}

            {/* <div className="flex gap-7 p-1 py-4 lg:py-2   justify-between">
              <div className="flex gap-8  w-11/12 lg:w-2/3 justify-between lg:justify-normal items-center">
                <div className="bg-gray-200 rounded-md w-16 h-12 flex justify-center items-center">
                  <StarBorderIcon />
                </div>
                <div className="flex flex-col w-2/3 ">
                  <div className="font-medium text-base sm:text-lg">Law Office of Adv Lavekar</div>
                  <div className="text-sm sm:text-base font-light text-gray-500">Owner</div>
                  <div className="hidden lg:flex text-sm sm:text-base font-light text-gray-500">
                    2005 - 2011
                  </div>
                </div>
                <div className="lg:hidden font-medium text-base whitespace-nowrap sm:text-lg">
                  2005 - 2011
                </div>
              </div>
            </div> */}
          </div>
          {/* <img alt="details" src={Details} className="object-contain mt-[-2%] flex lg:hidden" /> */}
        </div>
      </div>

      {/* Client Review Section*/}
      <div className="sm:px-6 sm:py-2  ">
        <div className="flex justify-between w-11/12 ">
          <div className="font-bold text-xl sm:text-2xl">Client Reviews</div>
        </div>

        <div className="flex justify-between py-1">
          <div className="w-11/12 sm:w-2/3 flex flex-col">
            {viewProfile?.reviews ? (
              viewProfile?.reviews?.map((items: any, index: number) => (
                <div key={index} className="flex flex-col p-2 sm:py-1 my-1 sm:my-2">
                  <div className="text-base font-semibold capitalize">{items.user_name}</div>
                  <div className="sm:mb-1 text-gray-500">{moment(items.is_created).calendar()}</div>
                  <div>
                    <Rating name="read-only" value={items.rating} readOnly />
                  </div>
                  <div className="text-sm sm:text-base font-medium my-1 sm:my-1 capitalize">
                    {items.comment}
                  </div>
                  <div className="flex gap-12 justufy-between ">
                    <div>
                      <ThumbUpOffAltIcon /> {items.like_count}
                    </div>
                    <div>
                      {' '}
                      <ThumbDownOffAltIcon /> {items.dislike_count}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">No data Found</div>
            )}
          </div>
        </div>
      </div>

      {/* Tab bar Section*/}
      <div className="flex justify-between sm:px-6 sm:py-2">
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="primary" // Tab text color
            indicatorColor="primary" // Indicator color
          >
            {/* <Tab label="Photos" sx={{ fontWeight: 'bold' }} /> Border bottom */}
            <Tab label="Social Media" sx={{ fontWeight: 'bold' }} /> Bold font weight
            <Tab label="Bar Association" sx={{ fontWeight: 'bolder' }} /> {/* Bold font weight */}
            {/* <Tab label="Achievements" sx={{ fontWeight: 'bold' }} /> Border bottom */}
            <Tab label="GST" sx={{ fontWeight: 'bold' }} /> {/* Italic font style */}
            <Tab label="Banking" sx={{ fontWeight: 'bold' }} /> {/* Italic font style */}
          </Tabs>
          {(value === 1 || value === 3) && (
            <div>
              <div className="w-full my-4">
                <div className="flex gap-7 p-2 sm:p-6 sm:py-2 justify-between">
                  <div className="flex gap-12 sm:gap-20 w-2/3  items-center">
                    <SignalCellularAltIcon />
                    <div className="flex flex-col w-2/3 sm:w-1/3">
                      <div className="font-medium text-base sm:text-lg">Bar Association</div>
                      <div className="text-sm sm:text-base font-light text-gray-500">
                        Maharashtra State Bar Association
                      </div>
                    </div>
                    <div className="font-medium text-base sm:text-lg"></div>
                  </div>
                </div>

                <div className="flex gap-7 p-2 sm:p-6 sm:py-2  justify-between">
                  <div className="flex gap-12 sm:gap-20 w-2/3 items-center">
                    <BusinessIcon />
                    <div className="flex flex-col w-2/3 sm:w-1/3">
                      <div className="font-medium text-base sm:text-lg">Preferred Court</div>
                      <div className="text-sm sm:text-base font-light text-gray-500">
                        Vashi Navi Mumbai District Court
                      </div>
                    </div>
                    <div className="font-medium text-base sm:text-lg"></div>
                  </div>
                </div>

                <div className="flex gap-7 p-2 sm:p-6 sm:py-2   ustify-between">
                  <div className="flex gap-12 sm:gap-20 w-2/3 items-center">
                    <BadgeIcon />
                    <div className="flex flex-col w-2/3 sm:w-1/3">
                      <div className="font-medium text-base sm:text-lg">Bar ID</div>
                      <div className="text-sm sm:text-base font-light text-gray-500">123456</div>
                    </div>
                    <div className="font-medium text-base sm:text-lg"></div>
                  </div>
                </div>

                <div className="flex gap-7 p-2 sm:p-6 sm:py-2   ustify-between">
                  <div className="flex gap-12 sm:gap-20 w-2/3 items-center">
                    <BadgeIcon />
                    <div className="flex flex-col w-2/3 sm:w-1/3">
                      <div className="font-medium text-base sm:text-lg">Bar ID</div>
                      <div className="text-sm sm:text-base font-light text-gray-500">123456</div>
                    </div>
                    <div className="font-medium text-base sm:text-lg"></div>
                  </div>
                </div>

                <div className="flex gap-7 p-2 sm:p-6 sm:py-2   ustify-between">
                  <div className="flex gap-12 sm:gap-20 w-2/3 items-center">
                    <BadgeIcon />
                    <div className="flex flex-col w-2/3 sm:w-1/3">
                      <div className="font-medium text-base sm:text-lg">Bar ID</div>
                      <div className="text-sm sm:text-base font-light text-gray-500">123456</div>
                    </div>
                    <div className="font-medium text-base sm:text-lg"></div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {(value === 0 || value === 4) && (
            <div>
              <div className="w-full my-4">
                <div className="flex gap-7 p-6 py-4 justify-between flex-col">
                  {viewProfile?.social_media?.map((items: any, index: number) => (
                    <div key={items.id} className="flex gap-12 sm:gap-20 w-2/3  items-center">
                      <SignalCellularAltIcon />
                      <div className="flex flex-col w-2/3 sm:w-1/3">
                        <div className="font-medium text-lg">{items.platform}</div>
                        <div className="text-base font-light text-gray-500">
                          {items.platform === 'WhatsApp' ? items.whatsapp_number : items.url}
                        </div>
                      </div>
                      <div className="font-medium text-lg"></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {value === 2 && (
            <div>
              <div className="w-full my-4">
                <div className="flex gap-7 p-6 py-4 justify-between">
                  <div className="flex gap-12 sm:gap-20 w-2/3  items-center">
                    <SignalCellularAltIcon />
                    <div className="flex flex-col w-2/3 sm:w-1/3">
                      <div className="font-medium text-lg">Bar Association</div>
                      <div className="text-base font-light text-gray-500">
                        Maharashtra State Bar Association
                      </div>
                    </div>
                    <div className="font-medium text-lg"></div>
                  </div>
                </div>

                <div className="flex gap-7 p-6 py-4  justify-between">
                  <div className="flex gap-12 sm:gap-20 w-2/3 items-center">
                    <BusinessIcon />
                    <div className="flex flex-col w-2/3 sm:w-1/3">
                      <div className="font-medium text-lg">Preferred Court</div>
                      <div className="text-base font-light text-gray-500">
                        Vashi Navi Mumbai District Court
                      </div>
                    </div>
                    <div className="font-medium text-lg"></div>
                  </div>
                </div>

                <div className="flex gap-7 p-6 py-4   ustify-between">
                  <div className="flex gap-12 sm:gap-20 w-2/3 items-center">
                    <BadgeIcon />
                    <div className="flex flex-col w-2/3 sm:w-1/3">
                      <div className="font-medium text-lg">Bar ID</div>
                      <div className="text-base font-light text-gray-500">123456</div>
                    </div>
                    <div className="font-medium text-lg"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <img alt="details" src={Details} className="absolute top-0 object-contain mt-[-2%] flex lg:hidden" /> */}
      </div>
    </div>
  )
}
export default LawyersDetails
