import { PaletteMode } from '@mui/material'
import Header from 'Shared/Header'
import Sidebar from 'Shared/Sidebar'
import React from 'react'

interface LayoutProps {
  component: React.ReactNode
  navItem?: any 
  mode: PaletteMode
  setMode: (mode: PaletteMode) => void
}
const Layout: React.FC<LayoutProps> = ({ component, navItem, mode, setMode }) => {
  return (
    <div className="flex w-screen h-screen gap-2 p-2 bg-gray-200 dark:bg-black">
      <Sidebar />
      <div className="flex flex-col w-full h-full gap-2">
        <Header mode={mode} setMode={setMode} navItem={navItem} />
        <div className="w-full h-full overflow-y-auto bg-white dark:bg-[#121212] dark:text-white rounded-lg shadow">
          {component}
        </div>
      </div>
    </div>
  )
}

export default Layout
