import SearchIcon from '@mui/icons-material/Search'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Switch,
  Divider,
  Box,
  Modal,
} from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { nfcCardListFn, switchNFCcard } from 'Services/NfcCard'
import { MkxButton } from 'Shared/Mkx'
import { MkxTableLoader } from 'Shared/MkxTable'
import { useState } from 'react'
import toast from 'react-hot-toast'
import QRCode from 'react-qr-code'
import { NavLink } from 'react-router-dom'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
}

const NFCcard: React.FC = () => {
  const [page, setPage] = useState(1)
  const [nfcId, setNfcId] = useState()
  const [search, setSearch] = useState('')
  const [openImage, setOpenImage] = useState(false)
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getNFCLists', search, page],
    queryFn: () => nfcCardListFn({ page, search }),
    refetchOnWindowFocus: false,
  })
  const { mutate: updateStatus } = useMutation({
    mutationFn: switchNFCcard,
    onSuccess(res) {
      toast.success(res.message)
      refetch()
    },
  })
  const handleActive = (e: any, id: any) => {
    console.log(e.target.checked)
    updateStatus({ nfc_id: id })
  }
  return (
    <div className="py-[2%] px-3 sm:px-[4%]">
      <div className="lg:mb-8 ">
        <h1 className=" text-lg lg:text-2xl mb-0 font-bold ">NFC Card Manaegments</h1>
        <p className="text-gray-400">Manage Likhit De{`’`}s NFC cards and assign them to people</p>
      </div>
      <div className="flex items-center bg-gray-200 rounded-md p-2 pl-4">
        <SearchIcon className="text-gray-500 ml-2" />
        <input
          className="appearance-none bg-transparent border-none w-full text-gray-800 mr-3 py-2 px-3 leading-tight focus:outline-none"
          type="text"
          placeholder="Search for a card or person..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>

      <div className="flex flex-col  sm:pt-5 ">
        <div className="font-bold text-xl sm:text-2xl"> Specialities</div>
        <div className="flex gap-7  my-4 justify-between">
          <div className="flex  gap-5 w-11/12   flex-wrap">
            <div className="w-1/3 lg:w-48 whitespace-nowrap rounded-md font-medium text-xs sm:text-sm flex justify-center items-center  bg-gray-200 p-2 leading-none">
              <span>Active</span>{' '}
            </div>
            <div className="w-1/3 lg:w-48 whitespace-nowrap rounded-md font-medium text-xs sm:text-sm flex justify-center items-center  bg-gray-200 p-2 leading-none">
              <span>InActive</span>{' '}
            </div>
            <div className="w-1/3 lg:w-48 whitespace-nowrap rounded-md font-medium text-xs sm:text-sm flex justify-center items-center  bg-gray-200 p-2 leading-none">
              <span>All</span>{' '}
            </div>
            <div className="w-1/3 lg:w-48 whitespace-nowrap rounded-md font-medium text-xs sm:text-sm flex justify-center items-center  bg-gray-200 p-2 leading-none">
              <span>Short by</span>{' '}
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto my-8 !border-2 rounded-lg">
        <TableContainer component={Paper}>
          <Table>
            <TableHead className="bg-gray-200">
              <TableRow>
                <TableCell className="!font-bold align-center">Lawyer ID</TableCell>
                <TableCell className="!font-bold align-center">Lawyer Name</TableCell>
                <TableCell className="!font-bold align-center">Card Type</TableCell>
                <TableCell className="!font-bold align-center">Card Id</TableCell>
                <TableCell className="!font-bold align-center">URL</TableCell>
                <TableCell className="!font-bold align-center">QR</TableCell>
                <TableCell className="!font-bold align-center">Action</TableCell>
                <TableCell className="!font-bold align-center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <MkxTableLoader loading={isLoading} row={6} />
              {data?.data?.map((items: any, index: number) => (
                <TableRow key={index}>
                  <TableCell className="!text-gray-500 !text-md !font-semibold">
                    {items?.lawyer[0]?.lawyerid}
                  </TableCell>
                  <TableCell>{items?.lawyer[0]?.name}</TableCell>
                  <TableCell className="!font-semibold">{items?.card_type[0].type}</TableCell>
                  <TableCell className="!text-gray-500">{items?.lawyer[0]?.nfcid}</TableCell>
                  <TableCell className="!text-gray-500">
                    <NavLink
                      to={`https://likhitde.com/nfc-cards/${items?.lawyer[0]?.nfcid}`}
                      className="hover:underline text-blue-400"
                      target="_blank"
                    >
                      {`https://likhitde.com/nfc-cards/${items?.lawyer[0]?.nfcid}`}
                    </NavLink>
                  </TableCell>
                  <TableCell>
                    <QRCode
                      onClick={() => {
                        setOpenImage(true)
                        setNfcId(items?.lawyer[0]?.nfcid)
                      }}
                      size={25}
                      style={{ height: 'auto', maxWidth: '100%', cursor: 'pointer', width: '100%' }}
                      value={`https://likhitde.com/nfc-cards/${items?.lawyer[0]?.nfcid}`}
                      viewBox={`0 0 256 256`}
                    />
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Switch
                      onChange={(e) => handleActive(e, items.id)}
                      checked={items.is_activate}
                    />
                  </TableCell>
                  <TableCell className="!font-bold">
                    {items?.is_activate === true ? 'Active' : 'Inactive'}
                  </TableCell>
                </TableRow>
              ))}

              {/* Add more rows as needed */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* <div className="flex justify-center py-5 ">
        {' '}
        <Pagination count={10} size="large" />
      </div> */}

      <div className="flex gap-8 px-5 justify-end">
        <MkxButton className="!rounded-lg !px-5 !font-bold !bg-gray-300 !text-black">
          Export
        </MkxButton>
        <MkxButton className="!rounded-lg !px-5 !font-bold" variant="contained">
          Import
        </MkxButton>
      </div>
      {/* View Full image */}
      <Modal
        keepMounted
        open={openImage}
        onClose={() => setOpenImage(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <div>
          <Box sx={style} className="!w-[90%] lg:!w-[45%]">
            {/* <div className="flex justify-between items-center px-4 pb-1">
              <div></div>
              <div className=" "></div>
              <div
                className=" text-red-500 text-3xl font-bold hover:cursor-pointer"
                onClick={() => setOpenImage(false)}
              >
                x
              </div>
            </div>
            <Divider className="!border-1 !border-gray-700 !mt-1 " /> */}

            <div className=" !max-h-[100vh] py-3 ">
              <div className="grid  ">
                {/* <img alt="" src={imgPic} className="h-[70vh] w-full bg-contain" /> */}
                <QRCode
                  size={25}
                  className="h-[70vh] !w-full   bg-contain"
                  // style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={`https://likhitde.com/nfc-cards/${nfcId}`}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
            {/* <Divider className="!border-1 !border-gray-700 !mt-3 " /> */}

            {/* <div className="flex justify-end py-4">
              <Button
                variant="contained"
                size="medium"
                type="submit"
                onClick={() => setOpenImage(false)}
                className="!px-6 !ml-4"
                // isLoading={isLoadingUpdate}
                // loadingContent="Applying.."
              >
                Cancel
              </Button>
            </div> */}
          </Box>
        </div>
      </Modal>
    </div>
  )
}
export default NFCcard
