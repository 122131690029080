import { AddCircleOutline, Delete, DeleteOutline, LibraryAddCheck } from '@mui/icons-material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Button, Chip, MenuItem, Modal, SelectChangeEvent, Tab } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createContractQuestionsFn, getContractQuestionsFn, updateContractQuestionsFn } from 'Services/ContractQuestions'
import {
  createContractTypeFn,
  getContractTypeFn,
  updateContractTypeFn,
} from 'Services/ContractType'
import withAuthentication from 'Shared/Authentication'
import CustomSelect from 'Shared/CustomSelect'
import { MkxButton, MkxInput } from 'Shared/Mkx'
import { SearchAndSelect } from 'Shared/SearchAndSelect'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

interface TabData {
  contract_id?: string | number;
  question_type: string;
  question: string;
  option: string[];
}
interface ExperienceProps {
  open: boolean
  fetch: () => void
  setOpen: (open: boolean) => void
  questionId?: number
  contractId?: number
}
const UpdateQustion: React.FC<ExperienceProps> = ({
  open,
  setOpen,
  fetch,
  questionId,
  contractId,
}) => {

  const [optionsList, setoptionsList] = useState<string[]>([])
  const [getOption, setOption] = useState<string>('')



  const { data: contractValue, isLoading: isLoadingContractData, refetch } = useQuery({
    queryKey: ['getContractQuestions', questionId,],
    queryFn: () => getContractQuestionsFn({ question_id: questionId }),
    refetchOnWindowFocus: false,
    select: (data) => data?.data
  })
  useEffect(() => {
    setoptionsList([contractValue?.option])
  }, [questionId])

  const { mutate: updateContractType, isPending: isCreating } = useMutation({
    mutationFn: updateContractQuestionsFn,
    onSuccess(res) {
      if (res.response_code === 200) {
        toast.success(res?.message)
        fetch()
        setOpen(false)
        // navigate('/contract', { state: contractId })
      }
      else {
        toast.error(res?.message)
      }
    },
  })
  const addOptions = () => {
    if (getOption !== '') {
      setoptionsList([...optionsList, getOption])
      setOption('')
    }
  }
  const handleDelete = (ids: number) => {
    const deletedData = optionsList.filter((_, index: number) => index !== ids)
    setoptionsList(deletedData)
  }

  const initialValues = {
    question_type: contractValue?.question_type ?? '',
    question: contractValue?.question ?? '',
    variable: contractValue?.variable ?? '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateContractType({ ...values, variable: values?.variable.toLowerCase().replace(" ", '_'), 'question_id': questionId, "option": optionsList })
    },
  })

  // const handleFormSubmit = (e: any) => {
  //   e.preventDefault()
  //   const finalValue = { contract_id: contractId, questions: tabmanage }
  //   updateContractType({ question_id: questionId })
  // }
  return (
    <div >
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description" >
        <Box className={"absolute rounded-lg z-10 top-1/2 left-1/2 bg-white outline-none -translate-x-1/2 overflow-y-auto -translate-y-1/2 w-1/3 shadow-md"}
        >
          <div className='text-xl py-3 text-center font-semibold'>Update Question</div>
          <form onSubmit={formik.handleSubmit} className="flex flex-col !relative  gap-5 p-5 ">

            {/* {tabmanage?.map((_, index: number) => <TabPanel key={index} value={`${index + 1}`}><div className="grid w-[70%]  ml-[10%] grid-cols-1 gap-5"> */}

            <div className='flex  font-semibold text-base items-center'>
              <div className='w-[30%]'>Question Type</div>
              <CustomSelect
                id="question_type"
                placeholder="Select Type"
                className='lg:!w-72 !w-52'
                value={formik.values.question_type}
                formik={formik as any} // Adjust the index as needed

              // onChange={function (
              //   event: SelectChangeEvent<{ value: string | number }>,
              //   child: ReactNode
              // ): void {
              //   throw new Error('Function not implemented.')
              // }}
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="single">Single Choice</MenuItem>
                {/* <MenuItem value="multiple">Multiple Choice</MenuItem> */}
                <MenuItem value="file">File</MenuItem>
                <MenuItem value="date">Date</MenuItem>
              </CustomSelect>
            </div>
            <div className='flex  font-semibold text-base items-center'>
              <div className='w-[30%]'>Variable</div>
              <MkxInput
                name="variable"
                placeholder="Write Variable"
                className='lg:!w-72 !w-52'
                value={formik.values.variable}
                onChange={formik.handleChange}
              // onChange={(e) => handleInputChange(index, 'question', e.target.value)}
              />
            </div>
            <div className='flex  font-semibold text-base items-center'>
              <div className='w-[30%]'>Question</div>
              <MkxInput
                name="question"
                multiline={true}
                rows={2}
                placeholder="Write Question"
                className='lg:!w-72 !w-52'
                value={formik.values.question}
                onChange={formik.handleChange}
              // onChange={(e) => handleInputChange(index, 'question', e.target.value)}
              />
            </div>
            {(formik.values.question_type === ('multiple') || formik.values.question_type === ('single')) && <div className='flex  font-semibold text-base items-center'>
              <div className='w-[30%]'>Options</div>
              <div className='flex flex-wrap gap-3 w-[60%]'>
                {optionsList?.length > 0 && optionsList?.map((item: string, index1: number) =>
                  <Chip key={index1}
                    onDelete={() => handleDelete(index1)}
                    label={item} />)}
                <div>
                  <input
                    required
                    id="option"
                    name="option"
                    placeholder="Write"
                    value={getOption}
                    onChange={(e) => setOption(e.target.value)}
                    className='!border  !outline-none !rounded-full !p-2 !w-24 !text-sm !pr-[20%] !font-normal  '
                  />
                  <AddCircleOutline onClick={addOptions} className='!text-primary !ml-[-21%]' />
                </div>
              </div>
            </div>}

            <div className="flex items-center justify-end gap-5">
              <MkxButton color="inherit" onClick={() => setOpen(false)}>
                Cancel
              </MkxButton>
              <MkxButton type="submit" loading={isCreating} >
                Submit
              </MkxButton>
            </div>
          </form >
        </Box>
      </Modal >
    </div >
  )
}

export default UpdateQustion;
