export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: { x: number; y: number; width: number; height: number },
  flip = { horizontal: false, vertical: false },
  returnType: 'blob' | 'base64' = 'blob'
): Promise<Blob | string | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-canvas.width / 2, -canvas.height / 2);

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  if (returnType === 'blob') {
    return new Promise<Blob | null>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Failed to convert canvas to Blob'));
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  } else if (returnType === 'base64') {
    return new Promise<string | null>((resolve, reject) => {
      try {
        const base64Image = canvas.toDataURL("image/jpeg");
        resolve(base64Image);
      } catch (error) {
        reject(new Error('Failed to convert canvas to Base64'));
      }
    });
  } else {
    return null;
  }
}

async function createImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
    img.src = url;
  });
}
