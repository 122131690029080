import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  CircularProgress,
  Divider,
  DividerProps,
  FormControl,
  IconButton,
  IconButtonProps,
  InputLabel,
  Modal,
  ModalProps,
  Paper,
  PaperProps,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
} from '@mui/material'

import classNames from 'classnames'
import { FormikProps } from 'formik'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'

/** Define props for MkxModal component */
interface MkxModalProps extends ModalProps {
  BoxProps?: BoxProps // Props for Box component
  title?: string // Title for the modal
}

/** Define props for MkxSelect component */

/** Define props for MkxSelect component */
interface MkxSelectProps extends Omit<SelectProps, 'value' | 'onChange'> {
  label?: string // Label for the select component
  size?: any
  value?: any
  onChange?: any
}
interface MkxButtonProps extends ButtonProps {
  loading?: boolean // Label for the select component
  loadingContent?: string // Label for the select component
}

/** Style for MkxModal */
const modalStyle: any = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
}

/** Button Component */
export const MkxButton: React.FC<MkxButtonProps> = ({
  variant = 'contained',
  className = '',
  loading = false,
  loadingContent = 'Loading...',
  ...rest
}) => {
  return loading ? (
    <Button disableElevation disabled variant="contained" endIcon={<CircularProgress size={20} />}>
      {loadingContent}
    </Button>
  ) : (
    <Button disableElevation variant={variant} className={classNames('', className)} {...rest} />
  )
}

/** IconButton Component */
export const MkxIconButton: React.FC<IconButtonProps> = ({
  size = 'small',
  className = '!bg-gray-200 dark:!bg-black !shadow hover:!bg-gray-300 hover:dark:!bg-black hover:dark:!bg-opacity-30 !rounded',
  ...rest
}) => {
  return <IconButton size={size} className={classNames('', className)} {...rest} />
}

/** TextField Component */
export const MkxInput: React.FC<TextFieldProps> = ({
  name = '',
  size = 'small',
  className = '',
  ...rest
}) => {
  return (
    <TextField id={name} name={name} className={classNames('', className)} size={size} {...rest} />
  )
}

/** Select Component */
export const MkxSelect: React.FC<MkxSelectProps> = ({
  size = 'small',
  className = '',
  name = '',
  label,
  ...rest
}) => {
  return (
    <FormControl size={size}>
      {label && <InputLabel id={name}>{label}</InputLabel>}
      <Select
        id={name}
        name={name}
        label={label}
        labelId={name}
        className={classNames('', className)}
        size={size}
        {...rest}
      />
    </FormControl>
  )
}

/** Divider Component */
export const MkxDivider: React.FC<DividerProps> = ({ className = '', ...rest }) => {
  return <Divider className={classNames('', className)} {...rest} />
}

/** Paper Component */
export const MkxPaper: React.FC<PaperProps> = ({ className = '', ...rest }) => {
  return <Paper className={classNames('', className)} {...rest} />
}

/** Modal Component */
export const MkxModal: React.FC<MkxModalProps> = ({ children, title, className = '', ...rest }) => {
  return (
    <Modal {...rest}>
      <Box sx={modalStyle} className={classNames('', className)}>
        {title && (
          <div className="flex items-center justify-center p-2">
            <p className="text-lg font-semibold">{title}</p>
          </div>
        )}
        <MkxDivider />
        {children}
      </Box>
    </Modal>
  )
}

export const randomArray = (start: number, end: number): number[] => {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i)
}

interface MkxEditorProps {
  formik: FormikProps<any>
}

export const MkxEditor: React.FC<MkxEditorProps> = ({ formik }) => {
  const [value, setValue] = useState('')
  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean'],
        ['link', 'image', 'video'],
      ],
    },
  }

  useEffect(() => {
    setValue(formik.values.content)
  }, [formik.values.content])

  const handleChange = (content: string) => {
    setValue(content)
    formik.setFieldValue('content', content)
  }

  return (
    <div className="min-h-[400px]">
      <ReactQuill
        modules={modules}
        theme="snow"
        value={value}
        onChange={handleChange}
        className="mkx"
      />
    </div>
  )
}

interface MkxImagePickerProps {
  formik: FormikProps<any>
  placeholder?: string
  name: string
}

export const MkxImagePicker: React.FC<MkxImagePickerProps> = ({
  formik,
  placeholder = 'Choose Image',
  name,
}) => {
  const value = formik.values[name]
  return (
    <MkxButton variant="outlined" component="label" className="!capitalize">
      {value ? value.name : placeholder}
      <input
        type="file"
        hidden
        accept="image/*"
        onChange={(event: any) => formik.setFieldValue(name, event.target.files[0])}
      />
    </MkxButton>
  )
}

export const MkxPDFPicker: React.FC<MkxImagePickerProps> = ({
  formik,
  placeholder = 'Choose PDF',
  name,
}) => {
  const value = formik.values[name]
  return (
    <MkxButton variant="outlined" component="label" className="!capitalize">
      {value ? value.name : placeholder}
      <input
        type="file"
        hidden
        accept='.pdf'
        onChange={(event: any) => formik.setFieldValue(name, event.target.files[0])}
      />
    </MkxButton>
  )
}
