import { API_URLs } from 'Configs/apis.config' // Importing API URLs from configuration
import axio from 'Configs/axios.config' // Importing Axios instance

/**
 * Function to fetch blog categories.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the fetched data.
 */
export const getAppointmentDistributionFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.get(API_URLs.appointment_distribution, { params: reqBody }) // Sending GET request to fetch blog categories
    return response.data // Returning the fetched data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}

/**
 * Function to create a new contract type.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the response data.
 */
export const appointmentUpdateCommissionFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.patch(API_URLs.appointment_distribution, reqBody) // Sending POST request to create a new contract type
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}
