import { MenuItem } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createBlogsFn, getBlogCategoriesFn, getBlogsFn, updateBlogsFn } from 'Services/Blog'
import withAuthentication from 'Shared/Authentication'
import { MkxButton, MkxEditor, MkxInput, MkxSelect } from 'Shared/Mkx'
import { ImagePicker } from 'Shared/useImageCrop/ImagePicker'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

const ManageBlog: React.FC = () => {
  const { state } = useLocation()
  const isUpdate = Boolean(state)
  const navigate = useNavigate()
  const { data: blogCategories } = useQuery({
    queryKey: ['getBlogCategories'],
    queryFn: getBlogCategoriesFn,
    refetchOnWindowFocus: false,
  })
  const { data } = useQuery({
    queryKey: ['getBlog', state],
    queryFn: () => getBlogsFn({ blog_id: state }),
    refetchOnWindowFocus: false,
    enabled: isUpdate,
  })
  const blog = data?.data

  const { mutate: createBlog, isPending: isCreating } = useMutation({
    mutationFn: createBlogsFn,
    onSuccess(res) {
      toast.success(res.message)
      navigate('/blog')
    },
  })
  const { mutate: updateBlog, isPending: isUpdating } = useMutation({
    mutationFn: updateBlogsFn,
    onSuccess(res) {
      toast.success(res.message)
      navigate('/blog')
    },
  })

  const initialValues = {
    title: blog?.title ?? '',
    sub_title: blog?.sub_title ?? '',
    meta_title: blog?.meta_title ?? '',
    slug: blog?.slug ?? '',
    meta_description: blog?.meta_description ?? '',
    content: blog?.content ?? 'Write your blog content here..',
    images: '',
    category_id: blog?.category_id ?? '',
  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit(values) {
      const reqBody = new FormData()
      reqBody.append('title', values.title)
      reqBody.append('sub_title', values.sub_title)
      reqBody.append('meta_title', values.meta_title)
      reqBody.append('slug', values.slug?.replaceAll(' ', '-')?.toLowerCase())
      reqBody.append('meta_description', values.meta_description)
      reqBody.append('content', values.content)
      reqBody.append('images', values.images)
      reqBody.append('category_id', values.category_id)
      isUpdate && reqBody.append('blog_id', state)
      isUpdate ? updateBlog(reqBody) : createBlog(reqBody)
    },
  })

  useEffect(() => {
    formik.setFieldValue('slug', formik.values.title)
  }, [formik.values.title])

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5 p-3">
        <div className="grid grid-cols-3 gap-5">
          <MkxInput
            required
            id="title"
            name="title"
            label="Title"
            placeholder="Enter Title"
            value={formik.values.title}
            onChange={formik.handleChange}
          />
          <ImagePicker name="images" formik={formik} placeholder="Blog Image" />

          <MkxInput
            name="sub_title"
            label="Sub Title"
            placeholder="Enter Sub Title"
            value={formik.values.sub_title}
            onChange={formik.handleChange}
          />
          <MkxInput
            name="meta_title"
            label="Meta Title"
            placeholder="Enter Meta Title"
            value={formik.values.meta_title}
            onChange={formik.handleChange}
          />
          <MkxInput
            required
            name="slug"
            label="Slug"
            placeholder="Enter Slug"
            value={formik.values.slug?.replaceAll(' ', '-')?.toLowerCase()}
            onChange={formik.handleChange}
          />
          <MkxSelect
            required
            name="category_id"
            label="Category"
            placeholder="Select Category"
            value={formik.values.category_id}
            onChange={formik.handleChange}
          >
            {blogCategories?.data?.map((i: { id: string | number | undefined; title: string }) => (
              <MenuItem key={i.id} value={i.id}>
                {i.title}
              </MenuItem>
            ))}
          </MkxSelect>
        </div>
        <MkxInput
          rows={3}
          multiline
          name="meta_description"
          label="Meta Description"
          placeholder="Enter Meta Description"
          value={formik.values.meta_description}
          onChange={formik.handleChange}
        />
        <MkxEditor formik={formik} />

        <div className="flex items-center justify-end gap-5">
          <MkxButton color="inherit" onClick={() => navigate(-1)}>
            Cancel
          </MkxButton>
          <MkxButton loading={isCreating || isUpdating} type="submit">
            Submit
          </MkxButton>
        </div>
      </form>
    </>
  )
}

export default withAuthentication(ManageBlog)
