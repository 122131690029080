import { ReactNode, useCallback, useState } from "react";
import "./index.css"
import ImageUploaderModal from "./ImageUploader";

const useImageCrop = ({ onComplete }: { onComplete?: any }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [image, setImage] = useState<string>();
  const [imgFile, setImgFile] = useState<any>();

  const [croppedImg, setCroppedImg] = useState<any>()

  function readFile(file: File) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const onClose = useCallback(() => {
    setImage("")
    setOpen(false)
  }, [])

  const onCancel = () => {
    setImgFile("")
    onClose()
  }

  const handleImageChange = useCallback(async (e: any) => {
    try {
      const file = e.target.files[0]
      setImgFile(file)
      const imageDataUrl = await readFile(file);
      if (imageDataUrl) {
        setImage(imageDataUrl as any);
        setOpen(true)
      }
    } catch (error) {
      console.log(error);
    }
  }, []);


  const ImageUploader = ({ children }: { children: ReactNode }) => {
    return (
      <>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id="image_input"
        />
        <label
          htmlFor="image_input"
          className="!cursor-pointer"
        >
          {
            imgFile ? imgFile.name : children
          }
        </label>
        <ImageUploaderModal open={open} image={image} onClose={onClose} onCancel={onCancel} onComplete={onComplete}
          setCroppedImg={setCroppedImg} />
      </>
    )
  }

  return { ImageUploader, croppedImg };
};

export default useImageCrop;