import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

const Report = () => {
  //   const comingSoon = () => toast('Comming Soon...!')
  return (
    <div className="flex flex-col !p-3">
      <p className="p-4 text-3xl font-semibold text-center">Reports</p>
      <div className="grid grid-cols-2 gap-3">
        <div className="flex flex-col p-3 pl-8 rounded-sm bg-gray-200 shadow-inner border-r border-white border-opacity-40">
          <div className="text-xl !rounded-none !p-2 font-bold"> Lawyer</div>
          <div className="flex flex-col gap-3 overflow-y-auto h-96">
            <Link
              to={'/report/lawyer-profile-visits'}
              className="hover:!text-blue-800 hover:!underline !underline-offset-2"
            >
              ➤ Profile visits
            </Link>
            <Link
              className="hover:!text-blue-800 hover:!underline !underline-offset-2"
              to={'/report/lawyer-services-user'}
            >
              ➤ Services Taker
            </Link>
            <Link
              className="hover:!text-blue-800 hover:!underline !underline-offset-2"
              to={'/report/lawyer-book-appointment'}
            >
              ➤ Book Appointment
            </Link>
          </div>
        </div>
        <div className="flex flex-col p-3 pl-8 rounded-sm bg-gray-200 shadow-inner border-r border-white border-opacity-40">
          <div className="text-xl !rounded-none !p-2 font-bold">Admin</div>
          <div className="flex flex-col gap-3  overflow-y-auto h-96">
            <div className="flex flex-col gap-3">
              <Link
                className="hover:!text-blue-800 hover:!underline !underline-offset-2"
                to={'/report/total-lawyers'}
              >
                ➤ Total Lawyers
              </Link>
              <Link
                className="hover:!text-blue-800 hover:!underline !underline-offset-2"
                to={'/report/total-active-lawyers'}
              >
                ➤ Total Active Lawyers
              </Link>
              <Link
                className="hover:!text-blue-800 hover:!underline !underline-offset-2"
                to={'/report/total-deactive-lawyers'}
              >
                ➤ Total Deactive Lawyers
              </Link>

              <Link
                className="hover:!text-blue-800 hover:!underline !underline-offset-2"
                to={'/report/total-clients'}
              >
                ➤ Total Clients
              </Link>
            </div>

            <div className="flex flex-col gap-3">
              <Link
                className="hover:!text-blue-800 hover:!underline !underline-offset-2"
                to={'/report/total-appointments'}
              >
                ➤ Total Appointments
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Report
