import { API_URLs } from 'Configs/apis.config' // Importing API URLs from configuration
import axio from 'Configs/axios.config' // Importing Axios instance

/**
 * Function to fetch blogs.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the fetched data.
 */
export const getFAQFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.get(API_URLs.faq, { params: reqBody }) // Sending GET request to fetch blogs
    return response.data // Returning the fetched data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}

/**
 * Function to create a new blog.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the response data.
 */
export const createFAQFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.post(API_URLs.faq, reqBody) // Sending POST request to create a new blog
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}

/**
 * Function to delete a blog.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the response data.
 */
export const deleteFAQFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.delete(API_URLs.faq, { data: reqBody }) // Sending DELETE request to delete a blog
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}

/**
 * Function to update a blog.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the response data.
 */
export const updateFAQFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.patch(API_URLs.faq, reqBody) // Sending PATCH request to update a blog
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}
