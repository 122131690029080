import { API_URLs } from 'Configs/apis.config' // Importing API URLs from configuration
import axio from 'Configs/axios.config' // Importing Axios instance

/**
 * Function to fetch blog categories.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the fetched data.
 */
export const couponListsFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.get(API_URLs.coupon_list_oprations, { params: reqBody }) // Sending GET request to fetch blog categories
    return response.data // Returning the fetched data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}

export const addCouponFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.post(API_URLs.add_coupon, reqBody) // Sending POST request to create a new contract type
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}
export const updateCouponFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.patch(API_URLs.coupon_list_oprations, reqBody) // Sending POST request to create a new contract type
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}
export const deleteCouponFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.delete(API_URLs.coupon_list_oprations, { data: reqBody }) // Sending POST request to create a new contract type
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}
export const enableCouponFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.post(API_URLs.coupon_enable, reqBody) // Sending POST request to create a new contract type
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}
