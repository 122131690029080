import { MenuItem, SelectChangeEvent } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createContractTypeFn,
  getContractTypeFn,
  updateContractTypeFn,
} from 'Services/ContractType'
import { lawyerUpdateFn } from 'Services/LawyerDetails/UpdateLawyer'
import withAuthentication from 'Shared/Authentication'
import CustomInput from 'Shared/CustomInput'
import CustomSelect from 'Shared/CustomSelect'
import { MkxButton, MkxImagePicker, MkxInput } from 'Shared/Mkx'
import { useFormik } from 'formik'
import React, { ReactNode, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

const CreateLawyer: React.FC = () => {
  const { state } = useLocation()
  const isUpdate = Boolean(state)
  const navigate = useNavigate()

  const { data } = useQuery({
    queryKey: ['getContractType', state],
    queryFn: () => getContractTypeFn({ contract_type_id: state }),
    refetchOnWindowFocus: false,
    enabled: isUpdate,
  })
  const contractType = data?.data

  const { mutate: createContractType, isPending: isCreating } = useMutation({
    mutationFn: createContractTypeFn,
    onSuccess(res) {
      toast.success(res.message)
      navigate('/lawyer-list')
    },
  })
  const { mutate: updateLawyer, isPending: isUpdating } = useMutation({
    mutationFn: lawyerUpdateFn,
    onSuccess(res) {
      toast.success(res.message)
      navigate('/lawyer-list')
    },
  })

  const initialValues = {
    name: contractType?.name ?? '',
    mobile: contractType?.mobile ?? '',
    is_Active: contractType?.is_Active ?? '',
    // meta_description: contractType?.meta_description ?? '',
    // images: '',
    // content: contractType?.content ?? '',
    // icon: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqBody = new FormData()
      reqBody.append('name', values.name)
      reqBody.append('mobile', values.mobile)
      // reqBody.append('is_Active', values.is_Active)
      isUpdate && reqBody.append('lawyer_id', state)
      isUpdate ? updateLawyer(reqBody) : createContractType(reqBody)
      // updateLawyer(reqBody)
    },
  })
  // useEffect(() => {
  //   formik.setFieldValue('slug', formik.values.title)
  // }, [formik.values.title])

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5 p-5">
        <div className="grid grid-cols-1 gap-8">
          <CustomInput
            required
            id="name"
            name="name"
            label="Name"
            placeholder="Enter Your Name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {/* 
          <MkxImagePicker name="images" formik={formik} placeholder="Contract Type Image" />
          <MkxImagePicker name="icon" formik={formik} placeholder="Contract Type Icon" /> */}

          <CustomInput
            name="mobile"
            id="mobile"
            label="Phone"
            placeholder="Enter Your Phone"
            value={formik.values.mobile}
            onChange={formik.handleChange}
          />
          {/* <CustomInput
            name="is_Active"
            id="is_Active"
            label="Status"
            placeholder="Enter Lawyer Status"
            value={formik.values.is_Active}
            onChange={formik.handleChange}
          />
          <CustomSelect
            id="status"
            label="Status"
            placeholder="Select Lawyer Status"
            formik={formik as any}
            value={''}
            onChange={function (
              event: SelectChangeEvent<{ value: string | number }>,
              child: ReactNode
            ): void {
              throw new Error('Function not implemented.')
            }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </CustomSelect> */}
        </div>
        {/* <MkxInput
          rows={3}
          multiline
          name="meta_description"
          label="Meta Description"
          placeholder="Enter Meta Description"
          value={formik.values.meta_description}
          onChange={formik.handleChange}
        />
        <MkxInput
          required
          rows={3}
          multiline
          name="content"
          label="Content"
          placeholder="Enter Content"
          value={formik.values.content}
          onChange={formik.handleChange}
        /> */}

        <div className="flex items-center justify-end gap-5">
          <MkxButton color="inherit" onClick={() => navigate(-1)}>
            Cancel
          </MkxButton>
          <MkxButton loading={isCreating || isUpdating} type="submit">
            Submit
          </MkxButton>
        </div>
      </form>
    </>
  )
}

export default CreateLawyer
