import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React from 'react'
import toast from 'react-hot-toast'
import { planTypeDeleteFn } from 'Services/PlanTypes'
import { transactionGetFn } from 'Services/Transactions'
import { MkxDivider, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableLoader,
  MkxTableRow,
} from 'Shared/MkxTable'

const Transaction: React.FC = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState<string>('')

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getTransaction', search, page],
    queryFn: transactionGetFn,
    refetchOnWindowFocus: false,
  })

  const blogs = data
  console.log('hiii', data)
  const { mutate: deleteTeam } = useMutation({
    mutationFn: planTypeDeleteFn,
    onSuccess(res) {
      toast.success(res.message)
      refetch()
    },
  })
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Transaction..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        {/* <MkxButton startIcon={<AddCircle />} onClick={() => navigate('/planType/create')}>
          New Plan
        </MkxButton> */}
      </div>
      <MkxDivider />
      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Transaction Id</MkxTableCell>
              <MkxTableCell>Date & Time</MkxTableCell>
              <MkxTableCell>Status</MkxTableCell>
              <MkxTableCell>Subscriber</MkxTableCell>
              <MkxTableCell>Method</MkxTableCell>
              <MkxTableCell>Amount</MkxTableCell>
              <MkxTableCell>GST</MkxTableCell>
              <MkxTableCell>GateWay Fee</MkxTableCell>

              <MkxTableCell>Likhit Amount</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            <MkxTableLoader loading={isLoading} row={8} />
            {blogs?.data?.map((blog: any) => (
              <MkxTableRow key={blog.id}>
                <MkxTableCell>{blog.tarnsaction_id}</MkxTableCell>
                <MkxTableCell>{moment(blog.created_date).calendar()}</MkxTableCell>
                <MkxTableCell>{blog.status}</MkxTableCell>
                <MkxTableCell>{blog.subscriber_name}</MkxTableCell>
                <MkxTableCell>{blog.payment_method}</MkxTableCell>
                <MkxTableCell>{blog.amount}</MkxTableCell>
                <MkxTableCell>{blog.gst}</MkxTableCell>
                <MkxTableCell>{blog.gateway_fee}</MkxTableCell>
                <MkxTableCell>{blog.likhit_amount}</MkxTableCell>
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>
        <MkxPagination data={data?.count} setPage={setPage} />
      </MkxTableContainer>
    </div>
  )
}

export default Transaction
