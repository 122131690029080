import { MenuItem } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { planTypeGetFn } from 'Services/PlanTypes'
import { getPlanPriceFn, plansCreateFn, plansUpdateFn } from 'Services/Plans'
import CustomSelect from 'Shared/CustomSelect'
import { MkxButton, MkxInput } from 'Shared/Mkx'
import { ImagePicker } from 'Shared/useImageCrop/ImagePicker'
import { useFormik } from 'formik'
import React from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

const ManagePlan: React.FC = () => {
  const { state } = useLocation()
  const isUpdate = Boolean(state)
  const navigate = useNavigate()
  const { data } = useQuery({
    queryKey: ['plansGet', state],
    queryFn: () => getPlanPriceFn({ planprice_id: state }),
    refetchOnWindowFocus: false,
    enabled: isUpdate,
  })
  const blog = data?.data

  const { data: planType } = useQuery({
    queryKey: ['planTypeGet'],
    queryFn: planTypeGetFn,
    refetchOnWindowFocus: false,
  })
  const typeData = planType?.data

  const { mutate: plansCreate, isPending: isCreating } = useMutation({
    mutationFn: plansCreateFn,
    onSuccess(res) {
      if (res?.response_code === 200) {
        toast.success(res.message)
        navigate('/subscription-plan')
      } else {
        toast.error(res.message)
      }
    },
  })
  const { mutate: plansUpdate, isPending: isUpdating } = useMutation({
    mutationFn: plansUpdateFn,
    onSuccess(res) {
      if (res?.response_code === 200) {
        toast.success(res.message)
        navigate('/subscription-plan')
      } else {
        toast.error(res.message)
      }
    },
  })

  const initialValues = {
    plan: blog?.plan ? typeData.find((item: any) => item.type == blog?.plan).id : '',
    type: blog?.type || '',
    price: blog?.price || '',
    discount: blog?.discount || '',
    type_image: blog?.type_image || '',
    gateway_fee: blog?.gateway_fee || '',
    gst: blog?.gst || '',
  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit(values) {
      const reqBody = new FormData()
      reqBody.append('plan', values.plan)
      reqBody.append('type', values.type)
      reqBody.append('price', values.price)
      reqBody.append('gst', values.gst)
      reqBody.append('type_image', values.type_image)
      reqBody.append('gateway_fee', values.gateway_fee)
      reqBody.append('discount', values.discount)
      isUpdate && reqBody.append('planprice_id', state)
      isUpdate ? plansUpdate(reqBody) : plansCreate(reqBody)
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5 p-3">
        <div className="grid grid-cols-1 gap-5">
          <CustomSelect
            id="plan"
            label="Plan Type"
            placeholder="Select Plan"
            formik={formik as any}
          // value={formik.values.plan}
          >
            {typeData?.map((items: any, index: number) => (
              <MenuItem key={index} value={items.id}>
                {items.type}
              </MenuItem>
            ))}
            {/* <MenuItem value="nfc">NFC Card</MenuItem>
            <MenuItem value="Other">Other</MenuItem> */}
          </CustomSelect>

          <MkxInput
            required
            id="type"
            name="type"
            label="type"
            placeholder="Enter type"
            value={formik.values.type}
            onChange={formik.handleChange}
          />

          <MkxInput
            required
            id="price"
            name="price"
            label="price"
            placeholder="Enter price"
            value={formik.values.price}
            onChange={formik.handleChange}
          />
          {formik.values.plan !== 'Subscription' && (
            <ImagePicker name="image" formik={formik} placeholder="Plan  Image" />
          )}
          <MkxInput
            required
            id="discount"
            name="discount"
            label="Discount"
            placeholder="Enter Discount"
            value={formik.values.discount}
            onChange={formik.handleChange}
          />
          <MkxInput
            required
            id="gst"
            name="gst"
            label="GST"
            placeholder="Enter GST"
            value={formik.values.gst}
            onChange={formik.handleChange}
          />
          <MkxInput
            required
            id="gateway_fee"
            name="gateway_fee"
            label="Gateway Fee"
            placeholder="Enter Gateway Fee"
            value={formik.values.gateway_fee}
            onChange={formik.handleChange}
          />
        </div>

        {/* <MkxEditor formik={formik} /> */}

        <div className="flex items-center justify-end gap-5">
          <MkxButton color="inherit" onClick={() => navigate(-1)}>
            Cancel
          </MkxButton>
          <MkxButton loading={isCreating || isUpdating} type="submit">
            Submit
          </MkxButton>
        </div>
      </form>
    </>
  )
}

export default ManagePlan
