import { Chip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React from 'react'
import { totalActiveLawyerFn } from 'Services/Reports'
import { MkxDivider, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableRow,
} from 'Shared/MkxTable'

const TotalActiveLawyers: React.FC = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState<string>('')

  const { data } = useQuery({
    queryKey: ['getTeam', search, page],
    queryFn: totalActiveLawyerFn,
    refetchOnWindowFocus: false,
  })

  const listData = data?.data
  console.log('hiii', data)
  //   const { mutate: deleteTeam } = useMutation({
  //     mutationFn: planTypeDeleteFn,
  //     onSuccess(res) {
  //       toast.success(res.message)
  //       refetch()
  //     },
  //   })
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Plan..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
      <MkxDivider />

      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Name</MkxTableCell>
              <MkxTableCell>Email</MkxTableCell>
              <MkxTableCell>Phone</MkxTableCell>
              <MkxTableCell>Gender</MkxTableCell>
              <MkxTableCell>Dob</MkxTableCell>
              <MkxTableCell>About</MkxTableCell>
              <MkxTableCell>Experiences</MkxTableCell>
              <MkxTableCell>Address</MkxTableCell>
              <MkxTableCell>Status</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            {listData?.map((row: any) => (
              <MkxTableRow key={row.id}>
                <MkxTableCell>{row.name || '-'}</MkxTableCell>
                <MkxTableCell>{row.email || '-'}</MkxTableCell>
                <MkxTableCell>{row.mobile || '-'}</MkxTableCell>
                <MkxTableCell>{row.gender || '-'}</MkxTableCell>
                <MkxTableCell>
                  {row.dob != null ? moment(row.dob).format('DD/MM/YYYY') : '-'}
                </MkxTableCell>
                <MkxTableCell>{row.about || '-'}</MkxTableCell>
                <MkxTableCell>{row.experience || '-'}</MkxTableCell>
                <MkxTableCell>{row.address || '-'}</MkxTableCell>
                <MkxTableCell>
                  <Chip
                    label={row.is_activate == true ? 'Active' : 'Dective'}
                    className="w-28"
                    // clickable={row.status === "Accepted" ? false : true}
                    color={row.is_activate != true ? 'primary' : 'success'}
                  />
                </MkxTableCell>
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>

        <MkxPagination data={data?.count} setPage={setPage} />
      </MkxTableContainer>
    </div>
  )
}

export default TotalActiveLawyers
