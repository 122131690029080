import { Autocomplete } from "@mui/material";
import CustomInput from "Shared/CustomInput";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { FormikProps } from "formik";

interface Option {
  value: string;
  label: string;
}

interface SearchAndSelectProps {
  options: Option[];
  label?: string;
  formik?: FormikProps<any>;
  name: string;
  setValue?: (value: string | null) => void;
  value?: string | null;
  isRequired?: boolean;
  className?: string;
  loading?: boolean;
  setChanges?: (value: string) => void;
  placeholder?: string;
}

export const SearchAndSelect: React.FC<SearchAndSelectProps> = ({
  options,
  label,
  formik,
  name,
  setValue,
  value,
  isRequired = false,
  className = "",
  loading = false,
  setChanges,
  placeholder = "Search and Select ",
}) => {
  const handleChange = (_event: any, newValue: Option | null) => {
    const selectedValue = newValue ? newValue.value : null;
    if (formik) {
      formik.setFieldValue(name, selectedValue);
    } else {
      setValue ? setValue(selectedValue) : toast.info("Provide setValue Function");
    }
  };

  let selectedOption: Option | undefined;

  if (formik) {
    selectedOption = options && options.find((option) => option.value === formik.values[name]);
  } else {
    selectedOption = options && options.find((option) => option.value === value);
  }

  return (
    <Autocomplete
      options={options || []}
      loading={loading}
      value={selectedOption || null}
      onChange={handleChange}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <CustomInput
          {...params}
          id={name}
          isRequired={isRequired}
          label={label}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setChanges && setChanges(e.target.value)}
          placeholder={label ? placeholder + label : placeholder}
          className={className}
        />
      )}
    />
  );
};
