import { API_URLs } from 'Configs/apis.config' // Importing API URLs from configuration
import axios from 'Configs/axios.config' // Importing Axios instance

/**
 * Function to fetch news.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the fetched data.  **/

export const lawyerListFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.lawyer_list, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const lawyerProfilesFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.lawyer_profile, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const lawyerKYCDetailsFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.lawyer_kyc_details, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
