import { DarkMode, LightMode } from '@mui/icons-material'
import { PaletteMode } from '@mui/material'
import { MkxIconButton } from 'Shared/Mkx'
import React from 'react'
interface HeaderProps {
  navItem: string
  mode: PaletteMode
  setMode: (mode: PaletteMode) => void
}
const Header: React.FC<HeaderProps> = ({ navItem, mode, setMode }) => {
  document.title = navItem

  return (
    <>
      <div className="flex w-full p-5 bg-white dark:bg-[#121212] justify-between items-center dark:text-white rounded-lg shadow">
        <p className="text-xl font-semibold">{navItem}</p>
        {/* <MkxIconButton onClick={() => setMode(mode === 'dark' ? 'light' : 'dark')}>
          {mode === 'dark' ? <LightMode /> : <DarkMode />}
        </MkxIconButton> */}
      </div>
    </>
  )
}

export default Header
