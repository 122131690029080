import { API_URLs } from 'Configs/apis.config'
import axio from 'Configs/axios.config'

/**
 * Function to fetch dashboard data from the API.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the dashboard data.
 */
export const getDashboardFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.get(API_URLs.dashboard, { params: reqBody })
    return response.data
  } catch (error) {
    console.error(error)
  }
}
