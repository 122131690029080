import { API_URLs } from 'Configs/apis.config' // Importing API URLs from configuration
import axios from 'Configs/axios.config' // Importing Axios instance

/**
 * Function to fetch news.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the fetched data.  **/

export const planTypeGetFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.crud_plan_type, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const planTypeCreateFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.post(API_URLs.crud_plan_type, reqBody, {
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const planTypeUpdateFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.patch(API_URLs.crud_plan_type, reqBody, {
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const planTypeDeleteFn = async (reqBody: any): Promise<any> => {
  console.log('jdaksjf', reqBody)
  try {
    const response = await axios.delete(API_URLs.crud_plan_type, { data: reqBody })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
