import { AddCircle, Delete, Edit, Visibility } from '@mui/icons-material'
import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { deleteContractTypeFn, getContractTypeFn } from 'Services/ContractType'
import withAuthentication from 'Shared/Authentication'
import { MkxButton, MkxDivider, MkxIconButton, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableLoader,
  MkxTableRow,
} from 'Shared/MkxTable'

const ContractTypes: React.FC = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState<string>('')
  const navigate = useNavigate()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getContractTypes', search, page],
    queryFn: () => getContractTypeFn({ page, search }),
    refetchOnWindowFocus: false,
  })

  const contractTypes = React.useMemo(() => {
    if (!data) return []
    return data.data.map(
      (category: { created_at: moment.MomentInput; updated_at: moment.MomentInput }) => ({
        ...category,
        created_at: moment(category.created_at).format('DD-MM-YYYY hh:mm A'),
        updated_at: moment(category.updated_at).format('DD-MM-YYYY hh:mm A'),
      })
    )
  }, [data])

  const { mutate: deleteContractType } = useMutation({
    mutationFn: deleteContractTypeFn,
    onSuccess(res) {
      toast.success(res.message)
      refetch()
    },
  })
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Contract Types..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />

        <MkxButton startIcon={<AddCircle />} onClick={() => navigate('/contract-type/create')}>
          New Contract Type
        </MkxButton>
      </div>
      <MkxDivider />
      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Title</MkxTableCell>
              {/* <MkxTableCell>Meta Title</MkxTableCell>
              <MkxTableCell>Meta Keyword</MkxTableCell> */}
              <MkxTableCell>Questions</MkxTableCell>
              <MkxTableCell>Publish Date </MkxTableCell>
              <MkxTableCell>Last Modified Date</MkxTableCell>
              <MkxTableCell>Action</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            <MkxTableLoader loading={isLoading} row={4} />
            {contractTypes?.map((contract_type: any) => (
              <MkxTableRow key={contract_type.id}>
                <MkxTableCell>{contract_type.title}</MkxTableCell>
                {/* <MkxTableCell>{contract_type.meta_title}</MkxTableCell> */}
                {/* <MkxTableCell>{contract_type.meta_keyword}</MkxTableCell> */}
                <MkxTableCell className='!w-36 !text-center'><Visibility onClick={() => navigate('/contract', { state: contract_type.id })} className='!text-green-600' /></MkxTableCell>
                <MkxTableCell>{contract_type.created_at}</MkxTableCell>
                <MkxTableCell>{contract_type.updated_at}</MkxTableCell>
                <MkxTableCell>
                  <span className="flex items-center gap-2">
                    <MkxIconButton
                      color="primary"
                      onClick={() => navigate('/contract-type/update', { state: contract_type.id })}
                    >
                      <Edit />
                    </MkxIconButton>
                    <MkxIconButton
                      color="error"
                      onClick={() => deleteContractType({ contract_type_id: contract_type.id })}
                    >
                      <Delete />
                    </MkxIconButton>
                    <MkxIconButton
                      color="success"
                      onClick={() =>
                        navigate(`/contract-type/${contract_type.id}/${contract_type.slug}`)
                      }
                    >
                      <Visibility />
                    </MkxIconButton>
                  </span>
                </MkxTableCell>
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>
        <MkxPagination data={data} setPage={setPage} />
      </MkxTableContainer>
    </div>
  )
}

export default withAuthentication(ContractTypes)
