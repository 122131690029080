import React from 'react'
import './loader.css'
import { Backdrop } from '@mui/material'

const Loader: React.FC = () => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <div className="loader">
        <div className="load-inner load-one"></div>
        <div className="load-inner load-two"></div>
        <div className="load-inner load-three"></div>
        <span className="text !text-center !text-blue-800 !text-2xl ">Loading...</span>
      </div>
    </Backdrop>
  )
}

export default Loader
