import {
  Pagination,
  PaginationProps,
  Skeleton,
  Table,
  TableBody,
  TableBodyProps,
  TableCell,
  TableCellProps,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableHeadProps,
  TableProps,
  TableRow,
  TableRowProps,
} from '@mui/material'

import { randomArray } from 'Shared/Mkx'
import classNames from 'classnames'

/**
 * Define type for MkxTableRowProps.
 */
interface MkxTableRowProps extends TableRowProps {
  /**
   * Indicates if it's a header row or not.
   */
  isHead?: boolean
}
/**
 * Define type for MkxTableLoaderProps.
 */
interface MkxTableLoaderProps {
  /**
   * Indicates if data is loading.
   */
  loading: boolean
  /**
   * Number of rows to render.
   */
  row: number
  /**
   * Number of columns to render.
   */
  col?: number
}
/**
 * Define type for MkxPaginationProps.
 */
interface MkxPaginationProps extends PaginationProps {
  setPage: (page: number) => void
  data: { total_pages: number; current_page: number }
}
/**
 * Table Container Component.
 */
export const MkxTableContainer: React.FC<TableContainerProps> = ({ ...rest }) => {
  return <TableContainer {...rest} />
}

/**
 * Table Component.
 */
export const MkxTable: React.FC<TableProps> = ({ ...rest }) => {
  return <Table {...rest} />
}

/**
 * Table Head Component.
 */
export const MkxTableHead: React.FC<TableHeadProps> = ({ className = '', ...rest }) => {
  return <TableHead {...rest} className={classNames('!font-bold', className)} />
}

/**
 * Table Body Component.
 */
export const MkxTableBody: React.FC<TableBodyProps> = ({ ...rest }) => {
  return <TableBody {...rest} />
}

/**
 * Table Row Component.
 */
export const MkxTableRow: React.FC<MkxTableRowProps> = ({ className = '', isHead, ...rest }) => {
  return (
    <TableRow
      className={classNames(
        '*:border-r *:!border-r-gray-200 dark:*:!border-opacity-20',
        isHead &&
          '*:!font-bold *:!border-r-white dark:*:!border-opacity-20 !bg-gray-200 dark:!bg-black',
        className
      )}
      {...rest}
    />
  )
}

/**
 * Table Cell Component.
 */
export const MkxTableCell: React.FC<TableCellProps> = ({ className, ...rest }) => {
  return <TableCell className={classNames('!py-3 !px-2 ', className)} {...rest} />
}

/**
 * Pagination Component.
 */

export const MkxPagination: React.FC<MkxPaginationProps> = ({
  variant = 'outlined',
  shape = 'rounded',
  data,
  showFirstButton = true,
  showLastButton = true,
  setPage,
  ...rest
}) => {
  const handlePageChange = (_event: any, page: number) => {
    setPage(page)
  }
  return data && data?.total_pages !== 1 ? (
    <div className="flex justify-end p-1 border-b border-gray-200 dark:!border-black">
      <Pagination
        {...rest}
        shape={shape}
        variant={variant}
        onChange={handlePageChange}
        page={data?.current_page ?? 0}
        count={data?.total_pages ?? 0}
        showFirstButton={showFirstButton}
        showLastButton={showLastButton}
      />
    </div>
  ) : (
    <></>
  )
}

/**
 * Table Loader Component.
 */
export const MkxTableLoader: React.FC<MkxTableLoaderProps> = ({ loading, row, col = 10 }) => {
  return (
    <>
      {loading ? (
        randomArray(0, col).map((i) => (
          <MkxTableRow key={i}>
            {randomArray(0, row).map((i) => (
              <MkxTableCell key={i}>
                <Skeleton />
              </MkxTableCell>
            ))}
          </MkxTableRow>
        ))
      ) : (
        <></>
      )}
    </>
  )
}
