import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React from 'react'
import { lawyerAppointmentFn } from 'Services/Reports'
import { MkxDivider, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableRow,
} from 'Shared/MkxTable'

const LawyerAppointment: React.FC = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState<string>('')

  const { data } = useQuery({
    queryKey: ['getTeam', search, page],
    queryFn: lawyerAppointmentFn,
    refetchOnWindowFocus: false,
  })

  const listData = data?.data
  console.log('hiii', data)
  //   const { mutate: deleteTeam } = useMutation({
  //     mutationFn: planTypeDeleteFn,
  //     onSuccess(res) {
  //       toast.success(res.message)
  //       refetch()
  //     },
  //   })
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Plan..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
      <MkxDivider />

      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow>
              <MkxTableCell>Name</MkxTableCell>
              <MkxTableCell>Email</MkxTableCell>
              <MkxTableCell>Phone</MkxTableCell>
              <MkxTableCell>Date</MkxTableCell>
              <MkxTableCell>Time</MkxTableCell>
              <MkxTableCell>Content</MkxTableCell>
              <MkxTableCell>Status</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            {listData?.map((row: any) => (
              <MkxTableRow key={row.id}>
                <MkxTableCell>{row.name}</MkxTableCell>
                <MkxTableCell>{row.email}</MkxTableCell>
                <MkxTableCell>{row.phone_no || '-'}</MkxTableCell>
                <MkxTableCell>{moment(row.date).format('DD/MM/YYYY')}</MkxTableCell>
                <MkxTableCell>{moment(row.time).format()}</MkxTableCell>
                <MkxTableCell>{row.content}</MkxTableCell>
                <MkxTableCell>{row.status}</MkxTableCell>
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>

        <MkxPagination data={data?.count} setPage={setPage} />
      </MkxTableContainer>
    </div>
  )
}

export default LawyerAppointment
