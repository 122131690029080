import ManageBlog from 'Components/Blog/ManageBlog'
import ViewBlog from 'Components/Blog/ViewBlog'
import ManageBlogCategory from 'Components/BlogCategory/ManageBlogCategory'
import ManageContractType from 'Components/ContractTypes/ManageContractType'
import ViewContractType from 'Components/ContractTypes/ViewContractType'
import ManageCoupon from 'Components/Coupon/CreateUpdate'
import CreateLawyer from 'Components/LawyerList/CreateLawyer'
import LawyersDetails from 'Components/LawyerList/ViewProfile'
import ManageNews from 'Components/News/ManageNews'
import ViewNews from 'Components/News/ViewNews'
import ManagePlanTypes from 'Components/PlanTypes/ManageBlog'
import TotalActiveLawyers from 'Components/Reports/Admin/TotalActiveLawyers'
import TotalAppointments from 'Components/Reports/Admin/TotalAppointment'
import TotalClients from 'Components/Reports/Admin/TotalClients'
import TotalDeactiveLawyers from 'Components/Reports/Admin/TotalDeactiveLawyers'
import TotalLawyers from 'Components/Reports/Admin/TotalLawyers'
import LawyerAppointment from 'Components/Reports/Appointment'
import LawyerProfileVisits from 'Components/Reports/ProfileVisit'
import LawyerServices from 'Components/Reports/Services'
import ManagePlan from 'Components/Subscription/ManageBlog'
import ManageTeam from 'Components/Team/ManageBlog'
import ViewTeam from 'Components/Team/ViewBlog'
import Transaction from 'Components/Transaction'
import AmountDistribution from 'Pages/Amount Distribution'
import Blog from 'Pages/Blog'
import BlogCategory from 'Pages/BlogCategory'
import Contract from 'Pages/Contract'
import ContractTypes from 'Pages/ContractType'
import Coupan from 'Pages/Coupan'
import Dashboard from 'Pages/Dashboard'
import Earning from 'Pages/Earning'
import LawyerDetails from 'Pages/LawyerDetails'
import News from 'Pages/News'
import Subscription from 'Pages/Plans'
import PlanTypes from 'Pages/PlansType'
import Report from 'Pages/Report'
import Team from 'Pages/Team'
import NFCcard from 'Pages/NFCcard'
import AppointmentDistribution from 'Pages/Appointment Distribution'
import ManageContract from 'Components/Contract/ManageContract'
import FAQ from 'Pages/FAQ'

export const routes = [
  {
    id: 1,
    path: '/dashboard',
    name: 'Dashboard',
    element: <Dashboard />,
  },
  {
    id: 2,
    path: '/blog',
    name: 'Blog',
    element: <Blog />,
  },
  {
    id: 3,
    path: '/news',
    name: 'News',
    element: <News />,
  },
  {
    id: 4,
    path: '/contract',
    name: 'Questionnaire',
    element: <Contract />,
  },
  {
    id: 5,
    path: '/blog/create',
    name: 'Create Blog',
    element: <ManageBlog />,
  },
  {
    id: 6,
    path: '/blog/update',
    name: 'Update Blog',
    element: <ManageBlog />,
  },
  {
    id: 7,
    path: '/news/create',
    name: 'Create News',
    element: <ManageNews />,
  },
  {
    id: 8,
    path: '/news/update',
    name: 'Update News',
    element: <ManageNews />,
  },
  {
    id: 9,
    path: '/blog-category',
    name: 'Blog Category',
    element: <BlogCategory />,
  },
  {
    id: 10,
    path: '/blog-category/create',
    name: 'Create Category',
    element: <ManageBlogCategory />,
  },
  {
    id: 11,
    path: '/blog-category/update',
    name: 'Update Category',
    element: <ManageBlogCategory />,
  },
  {
    id: 12,
    path: '/contract-type',
    name: 'Contract Type',
    element: <ContractTypes />,
  },
  {
    id: 13,
    path: '/contract-type/create',
    name: 'Create Contract Type',
    element: <ManageContractType />,
  },
  {
    id: 14,
    path: '/contract-type/update',
    name: 'Update Contract Type',
    element: <ManageContractType />,
  },
  {
    id: 15,
    path: '/blog/:blog_id/:slug',
    name: 'View Blog',
    element: <ViewBlog />,
  },
  {
    id: 16,
    path: '/news/:news_id/:slug',
    name: 'View News',
    element: <ViewNews />,
  },
  {
    id: 17,
    path: '/contract-type/:contract_type_id/:slug',
    name: 'View Contract Type',
    element: <ViewContractType />,
  },
  {
    id: 18,
    path: '/lawyer-list',
    name: 'View Lawyer List',
    element: <LawyerDetails />,
  },
  {
    id: 13,
    path: '/lawyer-list/create',
    name: 'Create New Lawyer',
    element: <CreateLawyer />,
  },
  {
    id: 14,
    path: '/lawyer-list/update',
    name: 'Update Lawyer',
    element: <CreateLawyer />,
  },
  {
    id: 32,
    path: '/lawyer-list/:id/',
    name: 'View Lawyer Profile',
    element: <LawyersDetails />,
  },
  {
    id: 15,
    path: '/team',
    name: 'Team Members',
    element: <Team />,
  },
  {
    id: 13,
    path: '/team/create',
    name: 'Create New Team Member',
    element: <ManageTeam />,
  },
  {
    id: 14,
    path: '/team/update',
    name: 'Update Team Member',
    element: <ManageTeam />,
  },
  {
    id: 15,
    path: '/team/:team_id',
    name: 'View Team',
    element: <ViewTeam />,
  },
  {
    id: 16,
    path: '/subscription-plan',
    name: 'Plans Details',
    element: <Subscription />,
  },
  {
    id: 17,
    path: '/planType',
    name: 'Plan Type',
    element: <PlanTypes />,
  },
  {
    id: 18,
    path: '/subscription-plan/create',
    name: 'Create New Subscription Plan',
    element: <ManagePlan />,
  },
  {
    id: 19,
    path: '/subscription-plan/update',
    name: 'Update Subscription Plan',
    element: <ManagePlan />,
  },
  {
    id: 20,
    path: '/planType/create',
    name: 'Create New Plan Types',
    element: <ManagePlanTypes />,
  },
  {
    id: 21,
    path: '/planType/update',
    name: 'Update Plan Types',
    element: <ManagePlanTypes />,
  },
  {
    id: 22,
    path: '/transaction',
    name: 'Transaction',
    element: <Transaction />,
  },
  {
    id: 23,
    path: '/report',
    name: 'Report',
    element: <Report />,
  },
  {
    id: 24,
    path: '/report/lawyer-profile-visits',
    name: 'Lawyer Profile Visitors',
    element: <LawyerProfileVisits />,
  },
  {
    id: 25,
    path: '/report/lawyer-services-user',
    name: 'Clients Taking Services ',
    element: <LawyerServices />,
  },
  {
    id: 26,
    path: '/report/lawyer-book-appointment',
    name: 'Lawyer Booking Appointment',
    element: <LawyerAppointment />,
  },
  {
    id: 27,
    path: '/report/total-appointments',
    name: 'Total Lawyers Booking Appointment',
    element: <TotalAppointments />,
  },
  {
    id: 28,
    path: '/report/total-lawyers',
    name: 'Total Lawyers',
    element: <TotalLawyers />,
  },
  {
    id: 29,
    path: '/report/total-active-lawyers',
    name: 'Total Active Lawyers ',
    element: <TotalActiveLawyers />,
  },
  {
    id: 30,
    path: '/report/total-deactive-lawyers',
    name: 'Total Deactive Lawyers ',
    element: <TotalDeactiveLawyers />,
  },
  {
    id: 31,
    path: '/direct-payment-distibution',
    name: 'Direct Payment Distribution',
    element: <AmountDistribution />,
  },
  {
    id: 32,
    path: '/earning',
    name: 'Earning',
    element: <Earning />,
  },
  {
    id: 33,
    path: '/create-coupon',
    name: 'Create Coupon',
    element: <ManageCoupon />,
  },
  {
    id: 34,
    path: '/update-coupon',
    name: 'Update Coupon',
    element: <ManageCoupon />,
  },
  {
    id: 35,
    path: '/coupons',
    name: 'Coupons',
    element: <Coupan />,
  },
  {
    id: 36,
    path: '/report/total-clients',
    name: 'Total Clients',
    element: <TotalClients />,
  },
  {
    id: 37,
    path: '/nfc-card',
    name: 'NFC Card',
    element: <NFCcard />,
  },
  {
    id: 38,
    path: '/appointment-distibution',
    name: 'Appointment Distribution',
    element: <AppointmentDistribution />,
  },

  {
    id: 39,
    path: '/contract-question-add',
    element: <ManageContract />,
  },
  {
    id: 40,
    path: '/faq',
    element: <FAQ />,
  },

]
