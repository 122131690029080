import { API_URLs } from 'Configs/apis.config' // Importing API URLs from configuration
import axio from 'Configs/axios.config' // Importing Axios instance

/**
 * Function to fetch blog categories.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the fetched data.
 */
export const getBlogCategoryFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.get(API_URLs.blogCategory, { params: reqBody }) // Sending GET request to fetch blog categories
    return response.data // Returning the fetched data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}

/**
 * Function to create a new blog category.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the response data.
 */
export const createBlogCategoryFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.post(API_URLs.blogCategory, reqBody) // Sending POST request to create a new blog category
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}

/**
 * Function to update an existing blog category.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the response data.
 */
export const updateBlogCategoryFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.put(API_URLs.blogCategory, reqBody) // Sending PATCH request to update an existing blog category
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}

/**
 * Function to delete a blog category.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the response data.
 */
export const deleteBlogCategoryFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axio.delete(API_URLs.blogCategory, { data: reqBody }) // Sending DELETE request to delete a blog category
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}
