import { useQuery } from '@tanstack/react-query'
import { getNewsFn } from 'Services/News'
import withAuthentication from 'Shared/Authentication'
import React from 'react'
import { useParams } from 'react-router-dom'

const ViewNews: React.FC = () => {
  const { news_id } = useParams()
  const { data } = useQuery({
    queryKey: ['getNewDetail', news_id],
    queryFn: () => getNewsFn({ news_id }),
    refetchOnWindowFocus: false,
  })
  const news = data?.data
  return (
    <div className="flex flex-col">
      <img src={news?.images} alt="" className="object-cover h-96" />
      <div className="flex flex-col gap-4 p-5">
        <p className="text-3xl font-bold text-center">{news?.title}</p>
        <div dangerouslySetInnerHTML={{ __html: news?.content }} className="px-[10%]"></div>
      </div>
    </div>
  )
}

export default withAuthentication(ViewNews)
