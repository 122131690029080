import { FormikProps } from "formik";
import { MkxButton } from "Shared/Mkx";
import useImageCrop from "./index";

interface MkxImagePickerProps {
  formik: FormikProps<any>
  placeholder?: string
  name: string
}

export const ImagePicker = ({ placeholder, name, formik }: MkxImagePickerProps) => {
  const onComplete = (croppedImg: any) => {
    formik.setFieldValue(name, croppedImg);
  };
  const { ImageUploader } = useImageCrop({ onComplete: onComplete })

  return (
    <MkxButton variant="outlined" component="label" className="!capitalize">
      <ImageUploader>
        {placeholder}
      </ImageUploader>
    </MkxButton>
  )
}
