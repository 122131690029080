import { API_URLs } from 'Configs/apis.config' // Importing API URLs from configuration
import axios from 'Configs/axios.config' // Importing Axios instance

/**
 * Function to fetch news.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the fetched data.  **/

export const lawyerUpdateFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.post(API_URLs.update_lawyer, reqBody, {
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const statusUpdateFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.post(API_URLs.update_status, reqBody, {
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
