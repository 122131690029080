import { AddCircleOutline, Delete, DeleteOutline, LibraryAddCheck } from '@mui/icons-material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Button, Chip, Divider, MenuItem, Modal, SelectChangeEvent, Tab } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createContractQuestionsFn, getContractQuestionsFn, updateContractQuestionsFn } from 'Services/ContractQuestions'
import {
  createContractTypeFn,
  getContractTypeFn,
  updateContractTypeFn,
} from 'Services/ContractType'
import { createFAQFn, getFAQFn, updateFAQFn } from 'Services/FAQ'
import withAuthentication from 'Shared/Authentication'
import CustomSelect from 'Shared/CustomSelect'
import { MkxButton, MkxInput } from 'Shared/Mkx'
import { SearchAndSelect } from 'Shared/SearchAndSelect'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

interface ExperienceProps {
  open: boolean
  fetch: () => void
  setOpen: (open: boolean) => void
  questionId?: number
  isUpdate: boolean
  setIsUpdate: (opne: boolean) => void
}
const ManageFAQ: React.FC<ExperienceProps> = ({
  open,
  setOpen,
  fetch,
  questionId,
  isUpdate,
  setIsUpdate
}) => {

  const { data: questionsValue, isLoading: isLoadingContractData, refetch } = useQuery({
    queryKey: ['faqQuestions', questionId,],
    queryFn: () => getFAQFn({ faq_id: questionId }),
    refetchOnWindowFocus: false,
    enabled: isUpdate,
  })

  const { mutate: createFAQ, isPending: isCreatingPending } = useMutation({
    mutationFn: createFAQFn,
    onSuccess(res) {
      if (res.response_code === 200) {
        toast.success(res?.message)
        fetch()
        formik.resetForm()
        setIsUpdate(false)
        setOpen(false)
      }
      else {
        toast.error(res?.message)
      }
    },
  })

  const { mutate: updateFAQ, isPending: isUpdatePending } = useMutation({
    mutationFn: updateFAQFn,
    onSuccess(res) {
      if (res.response_code === 200) {
        toast.success(res?.message)
        fetch()
        formik.resetForm()
        setIsUpdate(false)
        setOpen(false)
        // navigate('/contract', { state: contractId })
      }
      else {
        toast.error(res?.message)
      }
    },
  })


  const initialValues = {
    question: questionsValue?.data?.question ?? '',
    answer: questionsValue?.data?.answer ?? '',
  }
  console.log(questionsValue)
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      isUpdate ? updateFAQ({ ...values, faq_id: questionId }) : createFAQ(values)
    },
  })
  return (
    <div >
      <Modal
        open={open}
        onClose={() => { setOpen(false); setIsUpdate(false) }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description" >
        <Box className={"absolute rounded-lg z-10 top-1/2 left-1/2 bg-white outline-none -translate-x-1/2 overflow-y-auto -translate-y-1/2 w-2/5 shadow-md"}
        >
          <div className='text-xl py-3 text-center font-semibold'>{isUpdate ? 'Update Question' : 'Add Question'}
          </div>
          <Divider />
          <form onSubmit={formik.handleSubmit} className="flex flex-col  !relative  gap-5 p-5 ">

            {/* {tabmanage?.map((_, index: number) => <TabPanel key={index} value={`${index + 1}`}><div className="grid w-[70%]  ml-[10%] grid-cols-1 gap-5"> */}

            <div className='flex  font-semibold text-base items-center gap-6 w-full'>
              <div className='w-[30%] pl-10'>Question</div>
              <MkxInput
                name="question"
                placeholder="Write Question"
                className='w-[60%]'
                value={formik.values.question}
                onChange={formik.handleChange}
              // onChange={(e) => handleInputChange(index, 'question', e.target.value)}
              />
            </div>
            <div className='flex  font-semibold text-base items-center gap-6 w-full'>
              <div className='w-[30%] pl-10'>Answer</div>
              <MkxInput
                name="answer"
                multiline={true}
                rows={3}
                placeholder="Write Answer"
                className='w-[60%]'
                value={formik.values.answer}
                onChange={formik.handleChange}
              // onChange={(e) => handleInputChange(index, 'question', e.target.value)}
              />
            </div>

            <div className="flex items-center justify-end gap-5 pr-6">
              <MkxButton color="inherit" onClick={() => { setOpen(false); setIsUpdate(false) }}>
                Cancel
              </MkxButton>
              <MkxButton type="submit" loading={isCreatingPending || isUpdatePending} >
                Submit
              </MkxButton>
            </div>
          </form >
        </Box>
      </Modal >
    </div >
  )
}

export default ManageFAQ;
