import { useQuery } from '@tanstack/react-query'
import { getBlogsFn } from 'Services/Blog'
import withAuthentication from 'Shared/Authentication'
import React from 'react'
import { useParams } from 'react-router-dom'

const ViewBlog: React.FC = () => {
  const { blog_id } = useParams()
  const { data } = useQuery({
    queryKey: ['getBlogDetail', blog_id],
    queryFn: () => getBlogsFn({ blog_id }),
    refetchOnWindowFocus: false,
  })
  const blog = data?.data
  return (
    <div className="flex flex-col">
      <img src={blog?.images} alt="" />
      <div className="flex flex-col gap-4 p-5">
        <p className="text-3xl font-bold text-center">{blog?.title}</p>
        <p className="text-center uppercase">{blog?.category}</p>
        <div dangerouslySetInnerHTML={{ __html: blog?.content }} className="px-[10%]"></div>
      </div>
    </div>
  )
}

export default withAuthentication(ViewBlog)
