import { AddCircle, Delete, Edit } from '@mui/icons-material'
import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { deleteBlogCategoryFn, getBlogCategoryFn } from 'Services/BlogCategory'
import withAuthentication from 'Shared/Authentication'
import { MkxButton, MkxDivider, MkxIconButton, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableLoader,
  MkxTableRow,
} from 'Shared/MkxTable'

const BlogCategory: React.FC = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState<string>('')
  const navigate = useNavigate()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getBlogCategory', search, page],
    queryFn: () => getBlogCategoryFn({ page, search }),
    refetchOnWindowFocus: false,
  })

  const blogsCategories = React.useMemo(() => {
    if (!data) return []
    return data.data.map(
      (category: { created_at: moment.MomentInput; updated_at: moment.MomentInput }) => ({
        ...category,
        created_at: moment(category.created_at).format('DD-MM-YYYY hh:mm A'),
        updated_at: moment(category.updated_at).format('DD-MM-YYYY hh:mm A'),
      })
    )
  }, [data])

  const { mutate: deleteBlogCategories } = useMutation({
    mutationFn: deleteBlogCategoryFn,
    onSuccess(res) {
      toast.success(res.message)
      refetch()
    },
  })
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Blog Category..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <MkxButton startIcon={<AddCircle />} onClick={() => navigate('/blog-category/create')}>
          New Category
        </MkxButton>
      </div>
      <MkxDivider />
      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Title</MkxTableCell>
              <MkxTableCell>Meta Title</MkxTableCell>
              <MkxTableCell>Meta Keyword</MkxTableCell>
              <MkxTableCell>Publish Date </MkxTableCell>
              <MkxTableCell>Last Modified Date</MkxTableCell>
              <MkxTableCell>Action</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            <MkxTableLoader loading={isLoading} row={5} />
            {blogsCategories?.map((category: any) => (
              <MkxTableRow key={category.id}>
                <MkxTableCell>{category.title}</MkxTableCell>
                <MkxTableCell>{category.meta_title}</MkxTableCell>
                <MkxTableCell>{category.meta_keyword}</MkxTableCell>
                <MkxTableCell>{category.created_at}</MkxTableCell>
                <MkxTableCell>{category.updated_at}</MkxTableCell>
                <MkxTableCell>
                  <span className="flex items-center gap-2">
                    <MkxIconButton
                      color="primary"
                      onClick={() => navigate('/blog-category/update', { state: category.id })}
                    >
                      <Edit />
                    </MkxIconButton>
                    <MkxIconButton
                      color="error"
                      onClick={() => deleteBlogCategories({ category_id: category.id })}
                    >
                      <Delete />
                    </MkxIconButton>
                  </span>
                </MkxTableCell>
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>
        <MkxPagination data={data} setPage={setPage} />
      </MkxTableContainer>
    </div>
  )
}

export default withAuthentication(BlogCategory)
