import { MenuItem } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { addCouponFn, couponListsFn, updateCouponFn } from 'Services/Coupon'
import { planTypeGetFn } from 'Services/PlanTypes'
import { getPlanPriceFn, plansCreateFn, plansUpdateFn } from 'Services/Plans'
import CustomInput from 'Shared/CustomInput'
import CustomSelect from 'Shared/CustomSelect'
import { MkxButton, MkxImagePicker, MkxInput } from 'Shared/Mkx'
import { useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

const ManageCoupon: React.FC = () => {
  const { state } = useLocation()
  const isUpdate = Boolean(state)
  const navigate = useNavigate()
  console.log(state)
  const { data } = useQuery({
    queryKey: ['couponDetails', state],
    queryFn: () => couponListsFn({ coupon_id: state }),
    refetchOnWindowFocus: false,
    enabled: isUpdate,
  })
  const blog = data?.data
  console.log('coupon : ', blog)

  console.log(data)
  const { mutate: addCoupon, isPending: isCreating } = useMutation({
    mutationFn: addCouponFn,
    onSuccess(res) {
      console.log('Getting Response : ', res)
      toast.success(res.message)
      //   refetch()
    },
  })
  const { mutate: updateCoupon, isPending: isUpdating } = useMutation({
    mutationFn: updateCouponFn,
    onSuccess(res) {
      toast.success(res.message)
      navigate('/coupons')
    },
  })

  const initialValues = {
    couponcode: blog?.couponcode || '',
    couponname: blog?.couponname || '',
    coupon_description: blog?.coupon_description || '',
    lawyerid: blog?.lawyerid || '',
    amount: blog?.amount || '',
    percentage: blog?.percentage || '',
    maximumusageperperson: blog?.maximumusageperperson || '',
    totalusageallowed: blog?.totalusageallowed || '',
    minimumcartvalue: blog?.minimumcartvalue || '',
    excludegivingdiscount: blog?.excludegivingdiscount || '',
    maximumdiscountamount: blog?.maximumdiscountamount || '',
    start_date: blog?.start_date || '',
    end_date: blog?.end_date || '',
  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit(values) {
      const reqbody = new FormData()
      reqbody.append('couponcode', values.couponcode)
      reqbody.append('couponname', values.couponname)
      reqbody.append('coupon_description', values.coupon_description)
      reqbody.append('lawyerid', values.lawyerid)
      reqbody.append('amount', values.amount)
      reqbody.append('percentage', values.percentage)
      reqbody.append('maximumusageperperson', values.maximumusageperperson)
      reqbody.append('totalusageallowed', values.totalusageallowed)
      reqbody.append('minimumcartvalue', values.minimumcartvalue)
      reqbody.append('excludegivingdiscount', values.excludegivingdiscount)
      reqbody.append('maximumdiscountamount', values.maximumdiscountamount)
      reqbody.append('start_date', moment(values.start_date).format('YYYY-MM-DD'))
      reqbody.append('end_date', moment(values.end_date).format('YYYY-MM-DD'))
      isUpdate && reqbody.append('coupon_id', state)
      isUpdate ? updateCoupon(reqbody) : addCoupon(reqbody)
    },
  })

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col gap-5  pl-[10%] py-5 pr-[5%] lg:pr-[50%]"
      >
        <div className="grid grid-cols-1 gap-5">
          <CustomInput
            id="couponcode"
            label="Coupan Code "
            placeholder="SUMMER20"
            value={''}
            formik={formik as any}
            isRequired
          />
          <CustomInput
            id="couponname"
            label="Coupan Name "
            placeholder="SUMMER"
            value={''}
            formik={formik as any}
            isRequired
          />
          <CustomInput
            id="coupon_description"
            label="Description "
            multiline
            rows={4}
            placeholder="Description"
            value={''}
            formik={formik as any}
            isRequired
          />
          <CustomInput
            id="lawyerid"
            label="Lawyer ID "
            placeholder="12345"
            value={''}
            formik={formik as any}
            isRequired
          />
          <div className="grid grid-cols-3 gap-5">
            <CustomInput
              id="amount"
              label="Fixed Amount"
              placeholder="Enter Amount"
              value={''}
              formik={formik as any}
            />
            <CustomInput
              id="percentage"
              label="Percentage"
              placeholder="Enter Percentage"
              value={''}
              formik={formik as any}
            />
          </div>
          <CustomInput
            id="maximumusageperperson"
            label="Maximum Usage per person"
            placeholder="5"
            value={''}
            formik={formik as any}
          />
          <CustomInput
            id="totalusageallowed"
            label="Total Usage allowed"
            placeholder="500"
            value={''}
            formik={formik as any}
            className="!font-medium"
          />
          <CustomInput
            id="minimumcartvalue"
            label="Minimum cart value for applying"
            placeholder="1000"
            value={''}
            formik={formik as any}
          />
          <CustomInput
            id="excludegivingdiscount"
            label="Exclusive giving Discount on items "
            placeholder="eg Stamp paper"
            value={''}
            formik={formik as any}
          />
          <CustomInput
            id="maximumdiscountamount"
            label="Maximum discount amount"
            placeholder="500"
            value={''}
            formik={formik as any}
          />
          <CustomInput
            id="start_date"
            label="Start Date"
            type="date"
            placeholder="MM/DD/YYYY"
            value={''}
            formik={formik as any}
          />
          <CustomInput
            id="end_date"
            label="End Date"
            placeholder="MM/DD/YYYY"
            type="date"
            value={''}
            formik={formik as any}
          />
        </div>
        <div className="flex gap-6 !py-6 px-3">
          <MkxButton color="inherit" onClick={() => navigate(-1)}>
            Cancel
          </MkxButton>
          <MkxButton loading={isCreating || isUpdating} type="submit">
            Submit
          </MkxButton>
        </div>
        {/* <div className="flex items-center justify-end gap-5">
          
        </div> */}
      </form>
    </>
  )
}

export default ManageCoupon
