import axios from 'axios' // Importing Axios library
import toast from 'react-hot-toast' // Importing toast notifications library

/**
 * Base URL for API requests.
 */
// export const baseURL = 'http://192.168.1.71:6768/'
export const baseURL = 'https://admin1.likhitde.com/'

/**
 * Axios instance configured with base URL.
 */
const axio = axios.create({ baseURL })

/**
 * Axios request interceptor for adding authorization token to request headers.
 * @param config Axios request configuration.
 * @returns Axios request configuration with authorization token added.
 */
axio.interceptors.request.use(
  (config) => {
    // Extracting authorization token from local storage
    const Authorization = JSON.parse(localStorage.getItem('authentication') || '{}').token
    if (Authorization) {
      // Adding authorization token to request headers if available
      config.headers = config.headers || {}
      config.headers['Authorization'] = 'Token ' + Authorization
    }
    return config
  },
  (error) => Promise.reject(error)
)

/**
 * Axios response interceptor for handling errors and showing toast notifications.
 * @param error Axios error object.
 * @returns Promise that rejects with the error object.
 */
axio.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Displaying error message as toast notification
    error?.response?.data?.message && toast.error(error?.response?.data?.message)
    return Promise.reject(error)
  }
)

/**
 * Default export of the configured Axios instance for API requests.
 */
export default axio


export const imageFormat = (img: { img: string }) => {
  const newBaseURL = baseURL.slice(0, baseURL.length - 1);
  return `${newBaseURL}${img}`
}