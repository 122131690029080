import { AddCircle, Delete, Edit, Visibility } from '@mui/icons-material'
import { Box, Button, Divider, Modal, Switch } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { couponListsFn, deleteCouponFn, enableCouponFn } from 'Services/Coupon'
import { lawyerKYCDetailsFn, lawyerListFn } from 'Services/LawyerDetails'
import { deleteLawyerFn } from 'Services/LawyerDetails/DeleteLawyer'
import { statusUpdateFn } from 'Services/LawyerDetails/UpdateLawyer'
import withAuthentication from 'Shared/Authentication'
import { MkxButton, MkxDivider, MkxIconButton, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableLoader,
  MkxTableRow,
} from 'Shared/MkxTable'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const Coupon: React.FC = () => {
  const [page, setPage] = React.useState<number>(1)
  const [openKYC, setOpenKYC] = useState(false)
  const [couponId, setCouponId] = useState()
  const [search, setSearch] = React.useState<string>('')
  const navigate = useNavigate()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getCouponLists', search, page],
    queryFn: () => couponListsFn({ page, search }),
    refetchOnWindowFocus: false,
  })
  const CouponData = data?.data
  const { data: dataCoupon, refetch: refetchCoupon } = useQuery({
    queryKey: ['getCoupon', couponId],
    queryFn: () => couponListsFn({ coupon_id: couponId }),
    refetchOnWindowFocus: false,
  })
  const CouponDataSingle = dataCoupon?.data

  //   function handleDate(inputDate: string) {
  //     const parsedDate = new Date(inputDate.split('T')[0])
  //     const today = new Date()
  //     return Math.floor((today.getTime() - parsedDate.getTime()) / (1000 * 60 * 60 * 24))
  //   }
  const { mutate: deleteCoupon } = useMutation({
    mutationFn: deleteCouponFn,
    onSuccess(res) {
      console.log('Response in delete  : ', res)
      toast.success(res.message)
      refetch()
    },
  })
  const { mutate: updateStatus } = useMutation({
    mutationFn: enableCouponFn,
    onSuccess(res) {
      toast.success(res.message)
      refetch()
    },
  })
  const handleActive = (e: any, id: any) => {
    console.log(e.target.checked)
    updateStatus({ coupon_id: id })
  }
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Lawyers..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />

        <MkxButton startIcon={<AddCircle />} onClick={() => navigate('/create-coupon')}>
          New Coupon
        </MkxButton>
      </div>
      <MkxDivider />
      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Coupon Id</MkxTableCell>
              <MkxTableCell>Lawyer Id</MkxTableCell>
              <MkxTableCell>Coupon Name</MkxTableCell>
              <MkxTableCell>Coupon Code </MkxTableCell>
              <MkxTableCell>Start Date </MkxTableCell>
              <MkxTableCell>End Date</MkxTableCell>
              <MkxTableCell>Change Status</MkxTableCell>
              <MkxTableCell>Action</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            <MkxTableLoader loading={isLoading} row={7} />
            {CouponData?.map((items: any, index: number) => (
              <MkxTableRow key={items.id}>
                <MkxTableCell> {items.id ? items.id : '- -'}</MkxTableCell>
                <MkxTableCell> {items.lawyerid ? items.lawyerid : '- -'}</MkxTableCell>
                <MkxTableCell>{items.couponname}</MkxTableCell>
                <MkxTableCell>{items.couponcode}</MkxTableCell>
                <MkxTableCell>{moment(items.start_date).format('DD/MM/YYYY')}</MkxTableCell>
                <MkxTableCell>{moment(items.end_date).format('DD/MM/YYYY')}</MkxTableCell>

                <MkxTableCell className="!flex !justify-center">
                  <Switch onChange={(e) => handleActive(e, items.id)} checked={items.active} />
                </MkxTableCell>
                <MkxTableCell>
                  <span className="flex items-center gap-2">
                    <MkxIconButton
                      color="primary"
                      onClick={() => navigate('/update-coupon', { state: items.id })}
                    >
                      <Edit />
                    </MkxIconButton>
                    <MkxIconButton
                      color="error"
                      onClick={() => deleteCoupon({ coupon_id: items.id })}
                    >
                      <Delete />
                    </MkxIconButton>
                    <MkxIconButton
                      color="success"
                      onClick={() => {
                        setCouponId(items.id)
                        refetchCoupon()
                        setOpenKYC(true)
                      }}
                    >
                      <Visibility />
                    </MkxIconButton>
                  </span>
                </MkxTableCell>
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>
        <MkxPagination data={CouponData} setPage={setPage} />
      </MkxTableContainer>

      {/* View KYC */}
      <Modal
        keepMounted
        open={openKYC}
        onClose={() => setOpenKYC(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <div>
          <Box sx={style} className="!w-[90%] lg:!w-[45%]">
            <div className="flex justify-between items-center px-4 pb-4">
              <div></div>
              <div className="font-bold text-2xl ">Coupon Details</div>
              <div
                className=" text-red-500 text-3xl font-bold hover:cursor-pointer"
                onClick={() => setOpenKYC(false)}
              >
                x
              </div>
            </div>
            <Divider className="!border-1 !border-gray-700 !mt-3 " />

            <div className=" !max-h-[70vh] overflow-y-scroll hide-scroll">
              <div className="grid grid-cols-1 gap-3 pb-3 py-2">
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">Coupon Id</div>
                  <div className="border-2 py-3 pl-8">{CouponDataSingle?.id}</div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">Lawyer Id</div>
                  <div className="border-2 py-3 pl-8">{CouponDataSingle?.lawyerid}</div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">Coupon Code</div>
                  <div className="border-2 py-3 pl-8">{CouponDataSingle?.couponcode}</div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">Coupon Name</div>
                  <div className="border-2 py-3 pl-8">{CouponDataSingle?.couponname}</div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">Start Date</div>
                  <div className="border-2 py-3 pl-8">
                    {moment(CouponDataSingle?.start_date).format('DD/MM/YYYY')}
                  </div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">End Date</div>
                  <div className="border-2 py-3 pl-8">
                    {moment(CouponDataSingle?.end_date).format('DD/MM/YYYY')}
                  </div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">Fixed Amount</div>
                  <div className="border-2 py-3 pl-8">{CouponDataSingle?.amount}</div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">Percentage</div>
                  <div className="border-2 py-3 pl-8">{CouponDataSingle?.percentage}</div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">Maximum Usage per person</div>
                  <div className="border-2 py-3 pl-8">
                    {CouponDataSingle?.maximumusageperperson}
                  </div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">Total Usage allowed</div>
                  <div className="border-2 py-3 pl-8">{CouponDataSingle?.totalusageallowed}</div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">
                    Minimum cart value for applying
                  </div>
                  <div className="border-2 py-3 pl-8">{CouponDataSingle?.minimumcartvalue}</div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">
                    Exclusive giving Discount on items
                  </div>
                  <div className="border-2 py-3 pl-8">
                    {CouponDataSingle?.excludegivingdiscount}
                  </div>
                </div>
                <div className="grid grid-cols-2 mx-[13%]">
                  <div className="border-2 border-r-0 py-3 pl-8">Maximum discount amount</div>
                  <div className="border-2 py-3 pl-8">
                    {CouponDataSingle?.maximumdiscountamount}
                  </div>
                </div>
              </div>
            </div>
            <Divider className="!border-1 !border-gray-700 !mt-3 " />

            <div className="flex justify-end py-4">
              <Button
                variant="contained"
                size="medium"
                type="submit"
                onClick={() => setOpenKYC(false)}
                className="!px-6 !ml-4"
                // isLoading={isLoadingUpdate}
                // loadingContent="Applying.."
              >
                Cancel
              </Button>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  )
}

export default Coupon
