import { API_URLs } from 'Configs/apis.config' // Importing API URLs from configuration
import axios from 'Configs/axios.config' // Importing Axios instance

/**
 * Function to fetch news.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the fetched data.
 */
export const getNewsFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.news, { params: reqBody }) // Sending GET request to fetch news
    return response.data // Returning the fetched data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}

/**
 * Function to create a new news.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the response data.
 */
export const createNewsFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axios.post(API_URLs.news, reqBody) // Sending POST request to create a new news
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}

/**
 * Function to delete a news.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the response data.
 */
export const deleteNewsFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axios.delete(API_URLs.news, { data: reqBody }) // Sending DELETE request to delete a news
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}

/**
 * Function to update a news.
 * @param reqBody Optional request body.
 * @returns Promise that resolves to the response data.
 */
export const updateNewsFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axios.put(API_URLs.news, reqBody) // Sending PATCH request to update a news
    return response.data // Returning the response data
  } catch (error) {
    console.error(error) // Logging any errors that occur during the request
  }
}
