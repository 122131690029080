import { Card } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getDashboardFn } from 'Services/Dashboard'
import withAuthentication from 'Shared/Authentication'
import React from 'react'

const Dashboard: React.FC = () => {
  const { data } = useQuery({
    queryKey: ['dashboard'],
    queryFn: () => getDashboardFn(),
    refetchOnWindowFocus: false,
  })

  const counter = data?.data

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-4 *:h-32 gap-4 p-4">
        <Card className="!bg-gray-200 flex flex-col items-center justify-center dark:!bg-black">
          <p className="text-xl font-bold">{counter?.contracts ?? 0}</p>
          <p className="text-xl font-bold">Contract</p>
        </Card>
        <Card className="!bg-gray-200 flex flex-col items-center justify-center dark:!bg-black">
          <p className="text-xl font-bold">{counter?.blogs ?? 0}</p>
          <p className="text-xl font-bold">Blog</p>
        </Card>
        <Card className="!bg-gray-200 flex flex-col items-center justify-center dark:!bg-black">
          <p className="text-xl font-bold">{counter?.news ?? 0}</p>
          <p className="text-xl font-bold">News</p>
        </Card>
        <Card className="!bg-gray-200 flex flex-col items-center justify-center dark:!bg-black">
          <p className="text-xl font-bold">{counter?.users ?? 0}</p>
          <p className="text-xl font-bold">Users</p>
        </Card>
        <Card className="!bg-gray-200 flex flex-col items-center justify-center dark:!bg-black">
          <p className="text-xl font-bold">{counter?.team ?? 0}</p>
          <p className="text-xl font-bold">Team</p>
        </Card>
        <Card className="!bg-gray-200 flex flex-col items-center justify-center dark:!bg-black">
          <p className="text-xl font-bold">{counter?.lawyer ?? 0}</p>
          <p className="text-xl font-bold">Lawyers</p>
        </Card>
        <Card className="!bg-gray-200 flex flex-col items-center justify-center dark:!bg-black">
          <p className="text-xl font-bold">{counter?.active_lawyer ?? 0}</p>
          <p className="text-xl font-bold">Active Lawyers</p>
        </Card>
        <Card className="!bg-gray-200 flex flex-col items-center justify-center dark:!bg-black">
          <p className="text-xl font-bold">{counter?.deactive_lawyer ?? 0}</p>
          <p className="text-xl font-bold">Deactive Lawyers</p>
        </Card>
        <Card className="!bg-gray-200 flex flex-col items-center justify-center dark:!bg-black">
          <p className="text-xl font-bold">{counter?.client ?? 0}</p>
          <p className="text-xl font-bold">Clients</p>
        </Card>
      </div>
    </div>
  )
}

export default withAuthentication(Dashboard)
