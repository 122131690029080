import React from 'react'
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Skeleton,
  SelectChangeEvent,
} from '@mui/material'
import classNames from 'classnames'

interface Option {
  value: string | number
  label: React.ReactNode
}

interface CustomSelectProps {
  value?: string | number | Array<string | number>
  id?: string
  onChange?: (event: SelectChangeEvent<{ value: string | number }>, child: React.ReactNode) => void
  children?: React.ReactNode
  className?: string
  formik?: {
    values: Record<string, any>
    handleChange: (event: SelectChangeEvent<{ value: string | number }>) => void
    handleBlur: (event: React.FocusEvent<{ value: string | number }>) => void
    errors: Record<string, string>
    touched: Record<string, boolean>
  }
  isLoading?: boolean
  disabled?: boolean
  placeholder?: string
  multiple?: boolean
  options?: Option[]
  label?: string
  fullWidth?: boolean
  size?: 'small' | 'medium' | undefined
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  isRequired?: boolean
  isNone?: boolean
}

const Placeholder: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="text-gray-700">{children}</div>
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  value,
  id = '',
  onChange,
  children,
  className = '',
  formik,
  isLoading = false,
  disabled,
  placeholder = 'Select',
  multiple = false,
  options,
  label = '',
  fullWidth = false,
  size = 'small',
  color = 'primary',
  isRequired = false,
  isNone = false,
  ...rest
}) => {
  const selectedValue = formik?.values[id] ?? value ?? ''
  const handleChange = formik?.handleChange ?? onChange

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <p className="m-1 font-semibold whitespace-nowrap">
          {label}
          {isRequired && <span className="text-red-800">*</span>}
        </p>
      )}
      <Select
        displayEmpty
        color={color}
        value={selectedValue}
        onChange={handleChange}
        id={id}
        disabled={disabled ? disabled : false}
        multiple={multiple}
        size={size}
        error={!!(formik?.errors?.[id] && formik?.touched?.[id])}
        onBlur={formik?.handleBlur}
        name={id}
        renderValue={
          selectedValue?.length !== 0 ? undefined : () => <Placeholder>{placeholder}</Placeholder>
        }
        className={classNames(
          '!outline-none placeholder:!text-gray-100 !capitalize !bg-white !bg-opacity-20',
          className
        )}
        {...rest}
      >
        {isNone && <MenuItem value="">None</MenuItem>}
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <MenuItem key={index} disabled>
                <Skeleton style={{ width: '100%' }} />
              </MenuItem>
            ))
          : options
          ? options?.map((select) => (
              <MenuItem key={select?.value} value={select?.value}>
                {select?.label}
              </MenuItem>
            ))
          : children}
      </Select>
      {formik?.errors && (
        <FormHelperText sx={{ color: 'red' }}>
          {formik?.touched[id] && formik?.errors[id]}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default CustomSelect
