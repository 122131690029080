import { API_URLs } from 'Configs/apis.config'
import axios from 'Configs/axios.config'
import { log } from 'console'

export const lawyerListFn = async (reqBody: any): Promise<any> => {
  try {
    const response = await axios.get(API_URLs.lawyer_list, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}
export const lawyersDetailsFn = async (reqBody: any): Promise<any> => {
  console.log({ reqBody })

  try {
    const response = await axios.get(API_URLs.lawyers_Details, {
      params: reqBody,
      headers: { Authorization: localStorage.getItem('token1') },
    })
    return response.data
  } catch (error) {
    //     throw new Error(error.message)
    console.error(error)
  }
}

export const lawyerApproval = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axios.post(API_URLs.lawyer_approval, reqBody)
    return response.data
  } catch (error) {
    console.error(error)
    // throw new Error(error.message); // Optionally rethrow the error or handle it differently
  }
}
