import { Delete, Visibility } from '@mui/icons-material'
import { Box, Button, Divider, Modal, Switch } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { lawyerKYCDetailsFn, lawyerListFn } from 'Services/LawyerDetails'
import { deleteLawyerFn } from 'Services/LawyerDetails/DeleteLawyer'
import { statusUpdateFn } from 'Services/LawyerDetails/UpdateLawyer'
import withAuthentication from 'Shared/Authentication'
import { MkxButton, MkxDivider, MkxIconButton, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableLoader,
  MkxTableRow,
} from 'Shared/MkxTable'
import ManageStatus from './ApprovalStatus'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const LawyerDetails: React.FC = () => {
  const [page, setPage] = React.useState<number>(1)
  const [openKYC, setOpenKYC] = useState(false)
  const [lawyerId, setLawyerId] = useState()
  const [search, setSearch] = React.useState<string>('')
  const navigate = useNavigate()
  const [openImage, setOpenImage] = useState(false)
  const [imageData, setImageData] = useState('')

  const { data } = useQuery({
    queryKey: ['getContractTypes', lawyerId],
    queryFn: () => lawyerKYCDetailsFn({ lawyer_id: lawyerId }),
    refetchOnWindowFocus: false,
  })
  const KycData = data?.data

  // const contractTypes = React.useMemo(() => {
  //   if (!data) return []
  //   return data.data.map(
  //     (category: { created_at: moment.MomentInput; updated_at: moment.MomentInput }) => ({
  //       ...category,
  //       created_at: moment(category.created_at).format('DD-MM-YYYY hh:mm A'),
  //       updated_at: moment(category.updated_at).format('DD-MM-YYYY hh:mm A'),
  //     })
  //   )
  // }, [data])

  const {
    data: lawyerList,
    isLoading: isLoadingLawyerList,
    refetch,
  } = useQuery<any>({
    queryKey: ['lawyers', search, page],
    queryFn: () => lawyerListFn({ search, page }),
    refetchOnWindowFocus: false,
  })
  const lists = lawyerList?.data

  function handleDate(inputDate: string) {
    const parsedDate = new Date(inputDate.split('T')[0])
    const today = new Date()
    return Math.floor((today.getTime() - parsedDate.getTime()) / (1000 * 60 * 60 * 24))
  }
  const { mutate: deleteLawyer } = useMutation({
    mutationFn: deleteLawyerFn,
    onSuccess(res) {
      toast.success(res.message)
      refetch()
    },
  })
  const { mutate: updateStatus } = useMutation({
    mutationFn: statusUpdateFn,
    onSuccess(res) {
      toast.success(res.message)
      refetch()
    },
  })
  const handleActive = (e: any, id: any) => {
    console.log(e.target.checked)
    updateStatus({ lawyer_id: id })
  }
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Lawyers..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        {/* 
        <MkxButton startIcon={<AddCircle />} onClick={() => navigate('/lawyer-list/create')}>
          New Lawyer
        </MkxButton> */}
      </div>
      <MkxDivider />
      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Name</MkxTableCell>
              <MkxTableCell>Email</MkxTableCell>
              <MkxTableCell>Status</MkxTableCell>
              <MkxTableCell>KYC Attachment </MkxTableCell>
              <MkxTableCell>Phone </MkxTableCell>
              <MkxTableCell>Last Sign in</MkxTableCell>
              <MkxTableCell>Status</MkxTableCell>
              <MkxTableCell>Approval</MkxTableCell>
              <MkxTableCell>Action</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            <MkxTableLoader loading={isLoadingLawyerList} row={7} />
            {lists?.map((items: any) => (
              <MkxTableRow key={items.id}>
                <MkxTableCell> {items.name ? items.name : '- -'}</MkxTableCell>
                <MkxTableCell>{items.email}</MkxTableCell>
                <MkxTableCell>
                  <MkxButton variant="text" className=" !capitalize" color="inherit">
                    {items.is_activate === true ? 'Active' : 'Deactive'}
                  </MkxButton>
                </MkxTableCell>
                <MkxTableCell className=" !flex !justify-center">
                  <MkxButton
                    variant="text"
                    className="!bg-gray-200"
                    color="info"
                    onClick={() => {
                      setOpenKYC(true)
                      setLawyerId(items.id)
                    }}
                  >
                    view
                  </MkxButton>{' '}
                </MkxTableCell>
                <MkxTableCell>{items.mobile ? items.mobile : '- -'}</MkxTableCell>
                {/* <MkxTableCell>{contract_type.created_at}</MkxTableCell> */}
                <MkxTableCell>
                  {' '}
                  {handleDate(items.last_login) > 0
                    ? handleDate(items.last_login) + ' day ago'
                    : 'Today'}
                </MkxTableCell>
                <MkxTableCell>
                  {' '}
                  <Switch onChange={(e) => handleActive(e, items.id)} checked={items.is_activate} />
                </MkxTableCell>
                <MkxTableCell>
                  <ManageStatus refetch={refetch} status={items?.approval_status} id={items.id} />
                </MkxTableCell>
                <MkxTableCell>
                  <span className="flex items-center gap-2">
                    {/* <MkxIconButton
                      color="primary"
                      onClick={() => navigate('/lawyer-list/update', { state: items.id })}
                    >
                      <Edit />
                    </MkxIconButton> */}
                    <MkxIconButton
                      color="error"
                      onClick={() => deleteLawyer({ lawyer_id: items.id })}
                    >
                      <Delete />
                    </MkxIconButton>
                    <MkxIconButton
                      color="success"
                      onClick={() => navigate(`/lawyer-list/${items.id}`)}
                    >
                      <Visibility />
                    </MkxIconButton>
                  </span>
                </MkxTableCell>
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>
        <MkxPagination data={lawyerList} setPage={setPage} />
      </MkxTableContainer>

      {/* View KYC */}
      <Modal
        keepMounted
        open={openKYC}
        onClose={() => setOpenKYC(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <div>
          <Box sx={style} className="!w-[90%] lg:!w-[45%]">
            <div className="flex items-center justify-between px-4 pb-4">
              <div></div>
              <div className="text-2xl font-bold ">KYC Details</div>
              <div
                className="text-3xl font-bold text-red-500 hover:cursor-pointer"
                onClick={() => setOpenKYC(false)}
              >
                x
              </div>
            </div>
            <Divider className="!border-1 !border-gray-700 !mt-3 " />

            <div className=" !max-h-[70vh] overflow-y-scroll hide-scroll">
              <div className="grid grid-cols-1 gap-3 pb-3">
                <div className="grid items-center grid-cols-3 gap-3">
                  <div className="font-bold ">Aadhar Card</div>
                  <div>{KycData?.adhar_card_number ? KycData?.adhar_card_number : '- -'}</div>
                  <img
                    alt="images"
                    onClick={() => {
                      setOpenImage(true)
                      setImageData(
                        KycData?.pan_card_image
                          ? KycData.pan_card_image
                          : 'https://cdn.pixabay.com/photo/2017/01/25/17/35/picture-2008484_640.png'
                      )
                    }}
                    className="w-20 h-20"
                    src={
                      KycData?.adhar_card_image
                        ? KycData.adhar_card_image
                        : 'https://cdn.pixabay.com/photo/2017/01/25/17/35/picture-2008484_640.png'
                    }
                  />
                </div>
                <div className="grid items-center grid-cols-3 gap-3">
                  <div className="font-bold ">PAN Card</div>
                  <div>{KycData?.pan_card_number ? KycData?.pan_card_number : '- -'}</div>
                  <img
                    alt="images"
                    onClick={() => {
                      setOpenImage(true)
                      setImageData(
                        KycData?.pan_card_image
                          ? KycData.pan_card_image
                          : 'https://cdn.pixabay.com/photo/2017/01/25/17/35/picture-2008484_640.png'
                      )
                    }}
                    className="w-20 h-20"
                    src={
                      KycData?.pan_card_image
                        ? KycData.pan_card_image
                        : 'https://cdn.pixabay.com/photo/2017/01/25/17/35/picture-2008484_640.png'
                    }
                  />
                </div>
                <div className="grid items-center grid-cols-3 gap-3">
                  <div className="font-bold ">License Card</div>
                  <div>{KycData?.license_no ? KycData?.license_no : '- -'}</div>
                  <img
                    alt="images"
                    onClick={() => {
                      setOpenImage(true)
                      setImageData(
                        KycData?.pan_card_image
                          ? KycData.pan_card_image
                          : 'https://cdn.pixabay.com/photo/2017/01/25/17/35/picture-2008484_640.png'
                      )
                    }}
                    className="w-20 h-20"
                    src={
                      KycData?.license_image
                        ? KycData.license_image
                        : 'https://cdn.pixabay.com/photo/2017/01/25/17/35/picture-2008484_640.png'
                    }
                  />
                </div>
              </div>
            </div>
            <Divider className="!border-1 !border-gray-700 !mt-3 " />

            <div className="flex justify-end py-4">
              <Button
                variant="contained"
                size="medium"
                type="submit"
                onClick={() => setOpenKYC(false)}
                className="!px-6 !ml-4"
              // isLoading={isLoadingUpdate}
              // loadingContent="Applying.."
              >
                Cancel
              </Button>
            </div>
          </Box>
        </div>
      </Modal>
      {/* View Full image */}
      <Modal
        keepMounted
        open={openImage}
        onClose={() => setOpenImage(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <div>
          <Box sx={style} className="!w-[90%] lg:!w-[45%]">
            <div className="flex items-center justify-between px-4 pb-1">
              <div></div>
              <div className=""></div>
              <div
                className="text-3xl font-bold text-red-500 hover:cursor-pointer"
                onClick={() => setOpenImage(false)}
              >
                x
              </div>
            </div>
            <Divider className="!border-1 !border-gray-700 !mt-1 " />

            <div className=" !max-h-[80vh] ">
              <div className="grid grid-cols-1 gap-3 ">
                {/* <img alt="" src={imgPic} className="h-[70vh] w-full bg-contain" /> */}
                <img
                  // size={25}
                  alt="img"
                  className="h-[60vh] w-full bg-contain"
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  src={imageData}
                // viewBox={`0 0 256 256`}
                />
              </div>
            </div>
            {/* <Divider className="!border-1 !border-gray-700 !mt-3 " /> */}

            {/* <div className="flex justify-end py-4">
              <Button
                variant="contained"
                size="medium"
                type="submit"
                onClick={() => setOpenImage(false)}
                className="!px-6 !ml-4"
                // isLoading={isLoadingUpdate}
                // loadingContent="Applying.."
              >
                Cancel
              </Button>
            </div> */}
          </Box>
        </div>
      </Modal>
    </div>
  )
}

export default withAuthentication(LawyerDetails)
