import { AddCircle, Delete, Edit, Visibility } from '@mui/icons-material'
import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { teamDeleteFn, teamGetFn } from 'Services/Team'
import { MkxButton, MkxDivider, MkxIconButton, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableLoader,
  MkxTableRow,
} from 'Shared/MkxTable'

const Team: React.FC = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState<string>('')
  const navigate = useNavigate()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getTeam', search, page],
    queryFn: teamGetFn,
    refetchOnWindowFocus: false,
  })

  const blogs = data?.data
  console.log(data)
  const { mutate: deleteTeam } = useMutation({
    mutationFn: teamDeleteFn,
    onSuccess(res) {
      toast.success(res.msg)
      refetch()
    },
  })
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Blogs..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <MkxButton startIcon={<AddCircle />} onClick={() => navigate('/team/create')}>
          New Team
        </MkxButton>
      </div>
      <MkxDivider />
      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Name</MkxTableCell>
              <MkxTableCell>Role</MkxTableCell>

              <MkxTableCell>Action</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            <MkxTableLoader loading={isLoading} row={2} />
            {blogs?.map((blog: any) => (
              <MkxTableRow key={blog.id}>
                <MkxTableCell>{blog.name}</MkxTableCell>
                <MkxTableCell>{blog.role}</MkxTableCell>
                <MkxTableCell>
                  <span className="flex items-center gap-2">
                    <MkxIconButton
                      color="primary"
                      onClick={() => navigate('/team/update', { state: blog.id })}
                    >
                      <Edit />
                    </MkxIconButton>
                    <MkxIconButton color="error" onClick={() => deleteTeam({ team_id: blog.id })}>
                      <Delete />
                    </MkxIconButton>
                    <MkxIconButton color="success" onClick={() => navigate(`/team/${blog.id}`)}>
                      <Visibility />
                    </MkxIconButton>
                  </span>
                </MkxTableCell>
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>
        <MkxPagination data={data?.count} setPage={setPage} />
      </MkxTableContainer>
    </div>
  )
}

export default Team
