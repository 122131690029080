import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React from 'react'
import toast from 'react-hot-toast'
import { planTypeDeleteFn } from 'Services/PlanTypes'
import { lawyerServiceUsersFn } from 'Services/Reports'
import { MkxDivider, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableLoader,
  MkxTableRow,
} from 'Shared/MkxTable'

const LawyerServices: React.FC = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState<string>('')

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getservices', search, page],
    queryFn: lawyerServiceUsersFn,
    refetchOnWindowFocus: false,
  })

  const blogs = data
  console.log('hiii', data)
  const { mutate: deleteTeam } = useMutation({
    mutationFn: planTypeDeleteFn,
    onSuccess(res) {
      toast.success(res.message)
      refetch()
    },
  })
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Plan..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
      <MkxDivider />
      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Client Name</MkxTableCell>
              <MkxTableCell>Service Name</MkxTableCell>
              <MkxTableCell>Service Fees</MkxTableCell>
              <MkxTableCell>Lawyer Name</MkxTableCell>

              <MkxTableCell>Action</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            <MkxTableLoader loading={isLoading} row={5} />
            {blogs?.data?.map((blog: any) => (
              <MkxTableRow key={blog.id}>
                <MkxTableCell>{blog.user_name}</MkxTableCell>
                <MkxTableCell>{blog.service_name}</MkxTableCell>
                <MkxTableCell>{blog.service_fee}</MkxTableCell>
                <MkxTableCell>{blog.lawyer_name}</MkxTableCell>
                <MkxTableCell>{moment(blog.created_date).format('DD/MM/YYYY')}</MkxTableCell>

                {/* <MkxTableCell>
                  <span className="flex items-center gap-2">
                    <MkxIconButton
                      color="primary"
                      onClick={() => navigate('/planType/update', { state: blog.id })}
                    >
                      <Edit />
                    </MkxIconButton>
                    <MkxIconButton color="error" onClick={() => deleteTeam({ plan_id: blog.id })}>
                      <Delete />
                    </MkxIconButton>
                    <MkxIconButton color="success" onClick={() => navigate(`/planType/${blog.id}`)}>
                      <Visibility />
                    </MkxIconButton>
                  </span>
                </MkxTableCell> */}
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>
        <MkxPagination data={data?.count} setPage={setPage} />
      </MkxTableContainer>
    </div>
  )
}

export default LawyerServices
