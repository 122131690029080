import { useMutation, useQuery } from '@tanstack/react-query'
import { createNewsFn, getNewsFn, updateNewsFn } from 'Services/News'
import withAuthentication from 'Shared/Authentication'
import { MkxButton, MkxEditor, MkxInput } from 'Shared/Mkx'
import { ImagePicker } from 'Shared/useImageCrop/ImagePicker'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

const ManageNews: React.FC = () => {
  const { state } = useLocation()
  const isUpdate = Boolean(state)
  const navigate = useNavigate()

  const { data } = useQuery({
    queryKey: ['getNews', state],
    queryFn: () => getNewsFn({ news_id: state }),
    refetchOnWindowFocus: false,
    enabled: isUpdate,
  })
  const news = data?.data

  const { mutate: createNews, isPending: isCreating } = useMutation({
    mutationFn: createNewsFn,
    onSuccess(res) {
      toast.success(res.message)
      navigate('/news')
    },
  })
  const { mutate: updateNews, isPending: isUpdating } = useMutation({
    mutationFn: updateNewsFn,
    onSuccess(res) {
      toast.success(res.message)
      navigate('/news')
    },
  })

  const initialValues = {
    title: news?.title ?? '',
    meta_title: news?.meta_title ?? '',
    meta_keyword: news?.meta_keyword ?? '',
    slug: news?.slug ?? '',
    meta_description: news?.meta_description ?? '',
    content: news?.content ?? 'Write your news content here..',
    images: '',
  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit(values) {
      const reqBody = new FormData()
      reqBody.append('title', values.title)
      reqBody.append('meta_title', values.meta_title)
      reqBody.append('meta_keyword', values.meta_keyword)
      reqBody.append('slug', values.slug?.replaceAll(' ', '-')?.toLowerCase())
      reqBody.append('meta_description', values.meta_description)
      reqBody.append('content', values.content)
      reqBody.append('images', values.images)
      isUpdate && reqBody.append('news_id', state)
      isUpdate ? updateNews(reqBody) : createNews(reqBody)
    },
  })
  useEffect(() => {
    formik.setFieldValue('slug', formik.values.title)
  }, [formik.values.title])

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5 p-3">
        <div className="grid grid-cols-3 gap-5">
          <MkxInput
            required
            id="title"
            name="title"
            label="Title"
            placeholder="Enter Title"
            value={formik.values.title}
            onChange={formik.handleChange}
          />
          <ImagePicker name="images" formik={formik} placeholder="News Image" />

          <MkxInput
            name="meta_title"
            label="Meta Title"
            placeholder="Enter Meta Title"
            value={formik.values.meta_title}
            onChange={formik.handleChange}
          />
          <MkxInput
            name="meta_keyword"
            label="Meta Keyword"
            placeholder="Enter Meta Keyword"
            value={formik.values.meta_keyword}
            onChange={formik.handleChange}
          />
          <MkxInput
            required
            name="slug"
            label="Slug"
            placeholder="Enter Slug"
            value={formik.values.slug?.replaceAll(' ', '-')?.toLowerCase()}
            onChange={formik.handleChange}
          />
        </div>
        <MkxInput
          rows={3}
          multiline
          name="meta_description"
          label="Meta Description"
          placeholder="Enter Meta Description"
          value={formik.values.meta_description}
          onChange={formik.handleChange}
        />
        <MkxEditor formik={formik} />

        <div className="flex items-center justify-end gap-5">
          <MkxButton color="inherit" onClick={() => navigate(-1)}>
            Cancel
          </MkxButton>
          <MkxButton loading={isCreating || isUpdating} type="submit">
            Submit
          </MkxButton>
        </div>
      </form>
    </>
  )
}

export default withAuthentication(ManageNews)
