import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'Shared/MkxStorage'

function withAuthentication<P extends JSX.IntrinsicAttributes>(
  Component: React.ComponentType<P>
): React.FC<P> {
  const WithAuthentication: React.FC<P> = (props) => {
    const [auth] = useLocalStorage<any>('authentication', {})
    const navigate = useNavigate()

    useEffect(() => {
      if (!auth.token) navigate('/')
    }, [auth.token, navigate])

    return <Component {...props} />
  }

  return WithAuthentication
}

export default withAuthentication
