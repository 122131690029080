import { AddCircle, Delete, Edit } from '@mui/icons-material'
import { useMutation, useQuery } from '@tanstack/react-query'
import UpdateQustion from 'Components/Contract/UpdateQuestions'

import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { deleteContractQuestionsFn, getContractQuestionsFn } from 'Services/ContractQuestions'
import { getContractTypeFn } from 'Services/ContractType'
import withAuthentication from 'Shared/Authentication'
import { MkxButton, MkxDivider, MkxIconButton, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableLoader,
  MkxTableRow,
} from 'Shared/MkxTable'
import { SearchAndSelect } from 'Shared/SearchAndSelect'

const Contract: React.FC = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [changes, setChanges] = useState('')
  const [contractId, setContractId] = useState<number>()
  const [questionId, setQuestionId] = useState<number>()
  const [open, setOpen] = useState<boolean>(false)

  const { mutate: deleteContractType } = useMutation({
    mutationFn: deleteContractQuestionsFn,
    onSuccess(res) {
      toast.success(res.message)
      refetch()
    },
  })
  const { data, isLoading } = useQuery({
    queryKey: ['getContractTypes', changes],
    queryFn: () => getContractTypeFn({ search: changes }),
    refetchOnWindowFocus: false,
  })
  const contractList = data?.data?.map((i: any) => ({ label: i.title, value: i.id }))

  const { data: questionData, isLoading: Loading, refetch } = useQuery({
    queryKey: ['getContractQuestions', contractId, state, search, page,],
    queryFn: () => getContractQuestionsFn({ contract_id: (state ? state : contractId), page, search }),
    refetchOnWindowFocus: false,
  })
  const contractQuestions = questionData?.data
  return (
    <div className="flex flex-col p-3 h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search Question..." />
        <div className='flex gap-2'>
          <SearchAndSelect
            name="contract_id"
            // formik={formik}
            setValue={(value: any) => {
              setContractId(value);
              // handleInputChange(0, 'contract_id', value) 
            }}
            // label="Contranct"
            setChanges={setChanges}
            className='!w-48 '
            placeholder="Select Contract"
            options={contractList}
            loading={isLoading}
          />
          <MkxButton startIcon={<AddCircle />} onClick={() => navigate("/contract-question-add")}> New Questionnaire</MkxButton></div>
      </div>
      <MkxDivider />
      <MkxTableContainer className='!hide-scroll::-webkit-scrollbar'>
        <MkxTable className='!hide-scroll::-webkit-scrollbar'>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Contract</MkxTableCell>
              <MkxTableCell>Question Type</MkxTableCell>
              <MkxTableCell>Question</MkxTableCell>
              <MkxTableCell>Options</MkxTableCell>
              <MkxTableCell>Action</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            <MkxTableLoader loading={Loading} row={4} />
            {contractQuestions?.map((items: any) => (
              <MkxTableRow key={items.id} className='!border' >
                <MkxTableCell>{items?.contract?.length > 0 ? items?.contract[0]?.title : " - "}</MkxTableCell>
                <MkxTableCell>{items?.question_type ? items?.question_type : " - "}</MkxTableCell>
                <MkxTableCell>{items?.question ? items?.question : " - "}</MkxTableCell>
                <MkxTableCell className="!w-[35%]"><div className='flex  flex-wrap'>{items?.option ? items?.option?.join(" , ") : " - "}</div></MkxTableCell>
                <MkxTableCell><span className="flex items-center gap-2">
                  <MkxIconButton
                    color="primary"
                    onClick={() => {
                      setOpen(true);
                      setQuestionId(items.id)
                    }}
                  // onClick={() => navigate('/contract-type/update', { state })}
                  >
                    <Edit />
                  </MkxIconButton>
                  <MkxIconButton
                    color="error"
                    onClick={() => deleteContractType({ question_id: items.id })}
                  >
                    <Delete />
                  </MkxIconButton></span></MkxTableCell>
              </MkxTableRow>
            ))} 
          </MkxTableBody>
        </MkxTable>
      </MkxTableContainer>
      <MkxPagination data={questionData} setPage={setPage} />
      <UpdateQustion open={open} setOpen={setOpen} fetch={refetch} questionId={questionId} />
    </div>
  )
}

export default withAuthentication(Contract)
