import { Button, Dialog, DialogActions } from "@mui/material";
import { useCallback, useState } from "react";
import Cropper, { Area, Point } from "react-easy-crop";
import { getCroppedImg } from "./ImageCropFunctions";
import toast from "react-hot-toast";


const ImageUploaderModal = ({ open, onClose, image, onComplete, setCroppedImg, onCancel }:
  { open: boolean, onClose: any, image: any, onComplete: any, setCroppedImg: any, onCancel: any }) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (!croppedAreaPixels) {
      console.error("Cropped area pixels are null");
      return;
    }

    try {
      const croppedImage = await getCroppedImg(
        image!,
        croppedAreaPixels,
        {
          horizontal: false,
          vertical: false
        }
      );
      if (croppedImage) {
        const croppedFile = new File([croppedImage], 'cropped_image.jpg', { type: 'image/jpeg' });
        setCroppedImg(croppedFile);
        if (onComplete) {
          onComplete(croppedFile);
        }
      }
      onClose();
    } catch (e) {
      toast.error("Some error occured in uploading image");
    }
  }, [croppedAreaPixels, image, onComplete, onClose]);


  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="md"
      fullWidth
      className="!rounded-full !m-auto"
    >
      <div className="relative w-full pb-12 overflow-hidden bg-white">
        <div>
          <div
            className="container bg-gray-100"
          >
            <div className="crop-container">
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                zoomSpeed={4}
                maxZoom={4}
                zoomWithScroll={true}
                cropShape="round"
                showGrid={true}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          </div>
          <div className="absolute bottom-0 w-full bg-white">
            <DialogActions>
              <Button variant='outlined' onClick={onCancel}>Cancel</Button>
              <Button onClick={showCroppedImage} color="primary" variant="contained">
                Confirm
              </Button>
            </DialogActions>
          </div>
        </div>
      </div>
    </Dialog>
  )
}


export default ImageUploaderModal;