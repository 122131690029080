import { AddCircleOutline, Delete, DeleteOutline, LibraryAddCheck } from '@mui/icons-material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Button, Chip, Divider, MenuItem, SelectChangeEvent, Tab } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createContractQuestionsFn, getContractQuestionsFn } from 'Services/ContractQuestions'
import {
  createContractTypeFn,
  getContractTypeFn,
  updateContractTypeFn,
} from 'Services/ContractType'
import withAuthentication from 'Shared/Authentication'
import CustomSelect from 'Shared/CustomSelect'
import { MkxButton, MkxInput } from 'Shared/Mkx'
import { SearchAndSelect } from 'Shared/SearchAndSelect'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

interface TabData {
  contract_id?: string | number;
  question_type: string;
  question: string;
  variable: string;
  option: string[];
}

const ManageContract: React.FC = () => {
  const { state } = useLocation()
  const isUpdate = Boolean(state)
  const navigate = useNavigate()
  const [changes, setChanges] = useState('')
  const [contractId, setContractId] = useState()
  const [value, setValue] = React.useState('1');

  const [optionsList, setoptionsList] = useState<string[]>([])
  const [getOption, setOption] = useState<string>('')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const contractInitialQuestion = { question_type: "", question: '', variable: '', option: [] }
  const [tabmanage, setTabmanage] = useState<TabData[]>([contractInitialQuestion])

  const { data, isLoading } = useQuery({
    queryKey: ['getContractTypes', changes],
    queryFn: () => getContractTypeFn({ search: changes }),
    refetchOnWindowFocus: false,
  })
  const contractList = data?.data?.map((i: any) => ({ label: i.title, value: i.id }))



  // const { data: contractValue, isLoading: isLoadingContractData, refetch } = useQuery({
  //   queryKey: ['getContractQuestions', contractId,],
  //   queryFn: () => getContractQuestionsFn({ contract_id: contractId }),
  //   refetchOnWindowFocus: false,
  //   select:(data)=>data?.data
  // })
  // useEffect(()=>{
  //   setTabmanage(contractValue?.data)
  // },)

  const { mutate: createContractType, isPending: isCreating } = useMutation({
    mutationFn: createContractQuestionsFn,
    onSuccess(res) {
      if (res.response_code === 200) {
        toast.success(res?.message)
        navigate('/contract', { state: contractId })
      }
      else {
        toast.error(res?.message)
      }
    },
  })
  const addOptions = (index: number) => {
    // setoptionsList((prev: any) => [...prev, getOption])
    if (getOption !== '') {
      const updatedOptions = [...tabmanage[index].option, getOption];
      handleInputChange(index, 'option', updatedOptions);
      setOption('')
    }
  }
  const handleInputChange = (index: number, field: keyof TabData, value: any) => {
    const newClaims = [...tabmanage]

    newClaims[index][field] = value
    setTabmanage(newClaims)
  }


  const handleDelete = (i: number, ids: string) => {
    const deletedData = tabmanage[i]?.option?.filter((items) => items != ids)
    setoptionsList(deletedData)
  }
  const handleDeleteQuestion = (ids: number) => {
    const deletedData = tabmanage.filter((_, index: number) => index !== ids)
    setTabmanage(deletedData)
    const newIndex = ids;
    setValue(`${newIndex}`)
  }
  const addTabs = () => {
    setTabmanage([...tabmanage, { question_type: "", question: '', variable: "", option: [] }])
    const lenths = tabmanage.length
    setValue(`${lenths + 1}`)
  }

  const handleFormSubmit = (e: any) => {
    e.preventDefault()
    const finalValue = { contract_id: contractId, questions: tabmanage }
    createContractType(finalValue)
  }

  const toSnakeCase = (str: string) => str.toLowerCase().replace(" ", '_');

  return (
    <div className='w-[75vw] mx-auto'>
      <form className="flex flex-col !relative  gap-5 py-5 mt-7 border rounded shadow-lg  px-12">
        <div className='flex gap-4 items-center ml-4'>
          <div className='font-semibold text-lg '>Contract Questionnaire</div>
          <SearchAndSelect
            name="contract_id"
            // formik={formik}
            setValue={(value: any) => {
              setContractId(value);
              // handleInputChange(0, 'contract_id', value) 
            }}
            // label="Contranct"
            setChanges={setChanges}
            className='!w-72 !ml-6'
            placeholder="Select Contract"
            options={contractList}
            loading={isLoading}
          /></div>
        <Divider sx={{}} />
        <TabContext value={value}  >
          <Box sx={{ margin: 3, marginTop: 1, width: "95%", borderColor: 'dodgerblue', overflow: 'scroll' }}>
            <TabList indicatorColor="secondary"
              className="!overflow-scroll scroller "
              textColor="inherit"
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              visibleScrollbar={false}
              allowScrollButtonsMobile={true}
              scrollButtons="auto"  >
              {tabmanage?.map((_, index: number) => <Tab key={index} className='!border !bg-primary !border-white !text-white  !text-base !py-0' label={`Question ${index + 1}`} value={`${index + 1}`} />
              )}
              <Tab className='!border !bg-primary !border-zinc-50 !text-white  !text-base !py-0' onClick={addTabs} label={<AddCircleOutline />} value="" />

            </TabList>
          </Box>
          {tabmanage?.map((_, index: number) => <TabPanel key={index} value={`${index + 1}`}><div className="grid w-[70%]  ml-[10%] grid-cols-1 gap-5">

            <div className='flex  font-semibold text-base items-center'>
              <div className='w-[30%]'>Question Type</div>
              <CustomSelect
                id="question_type"
                placeholder="Select Type"
                className='w-72'
                value={tabmanage[index].question_type} // Adjust the index as needed
                onChange={(e: SelectChangeEvent<any>) => {
                  handleInputChange(index, 'question_type', e.target.value) // Adjust the index as needed
                }}
              // onChange={function (
              //   event: SelectChangeEvent<{ value: string | number }>,
              //   child: ReactNode
              // ): void {
              //   throw new Error('Function not implemented.')
              // }}
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="single">Single Choice</MenuItem>
                {/* <MenuItem value="multiple">Multiple Choice</MenuItem> */}
                <MenuItem value="file">File</MenuItem>
                <MenuItem value="date">Date</MenuItem>
              </CustomSelect>
            </div>
            <div className='flex  font-semibold text-base items-center'>
              <div className='w-[30%]'>Variable</div>
              <MkxInput
                name="variable"
                placeholder="Write Variable"
                className='w-72'
                value={tabmanage[index].variable}
                onChange={(e) => handleInputChange(index, 'variable', toSnakeCase(e.target.value))}
              />
            </div>
            <div className='flex  font-semibold text-base items-center'>
              <div className='w-[30%]'>Question</div>
              <MkxInput
                name="question"
                multiline
                rows={2}
                placeholder="Write Question"
                className='w-72'
                value={tabmanage[index].question}
                onChange={(e) => handleInputChange(index, 'question', e.target.value)}
              />
            </div>
            {(tabmanage[index].question_type === ('multiple') || tabmanage[index].question_type === ('single')) && <div className='flex  font-semibold text-base items-center'>
              <div className='w-[30%]'>Options</div>
              <div className='flex flex-wrap gap-3 w-[60%]'>
                {tabmanage[index]?.option.length > 0 && tabmanage[index]?.option?.map((item: string, index1: number) =>
                  <Chip key={index1}
                    onDelete={() => handleDelete(index, item)}
                    label={item} />)}
                <div>
                  <input
                    required
                    id="option"
                    name="option"
                    placeholder="Write"
                    value={getOption}
                    onChange={(e) => setOption(e.target.value)}
                    className='!border  !outline-none !rounded-full !p-2 !w-28 !text-sm !pr-[20%] !font-normal  '
                  />
                  <AddCircleOutline onClick={() => addOptions(index)} className='!text-primary !ml-[-21%]' />
                </div>
              </div>
            </div>}
            <Button variant='outlined' className='!absolute !top-[50%] !right-16 !border-2 !border-primary !font-semibold' onClick={() => handleDeleteQuestion(index)} > Delete<Delete className='!text-red-600' /></Button>
          </div></TabPanel>)}
        </TabContext >



        <div className="flex items-center justify-end gap-5">
          <MkxButton color="inherit" onClick={() => navigate(-1)}>
            Cancel
          </MkxButton>
          <MkxButton type="submit" loading={isCreating} onClick={handleFormSubmit}>
            Submit
          </MkxButton>
        </div>
      </form >
    </div>
  )
}

export default withAuthentication(ManageContract)
