import { Chip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React from 'react'
import { totalLawyerAppointmentFn } from 'Services/Reports'
import { MkxDivider, MkxInput } from 'Shared/Mkx'
import {
  MkxPagination,
  MkxTable,
  MkxTableBody,
  MkxTableCell,
  MkxTableContainer,
  MkxTableHead,
  MkxTableRow,
} from 'Shared/MkxTable'

const TotalAppointments: React.FC = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState<string>('')

  const { data } = useQuery({
    queryKey: ['getTeam', search, page],
    queryFn: totalLawyerAppointmentFn,
    refetchOnWindowFocus: false,
  })

  const listData = data?.data
  console.log('hiii', data)
  //   const { mutate: deleteTeam } = useMutation({
  //     mutationFn: planTypeDeleteFn,
  //     onSuccess(res) {
  //       toast.success(res.message)
  //       refetch()
  //     },
  //   })
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-3">
        <MkxInput
          placeholder="Search Plan..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
      <MkxDivider />

      <MkxTableContainer>
        <MkxTable>
          <MkxTableHead>
            <MkxTableRow isHead>
              <MkxTableCell>Sr No</MkxTableCell>
              <MkxTableCell>Client Name</MkxTableCell>
              <MkxTableCell> Email</MkxTableCell>
              <MkxTableCell> Phone</MkxTableCell>
              <MkxTableCell>Date</MkxTableCell>
              <MkxTableCell>Time</MkxTableCell>
              <MkxTableCell>Content</MkxTableCell>
              {/* <MkxTableCell>Amount</MkxTableCell>
              <MkxTableCell>Payment Status</MkxTableCell> */}
              <MkxTableCell>Lawyer Name</MkxTableCell>
              <MkxTableCell>Status</MkxTableCell>
            </MkxTableRow>
          </MkxTableHead>
          <MkxTableBody>
            {listData?.map((row: any, index: number) => (
              <MkxTableRow key={row.id}>
                <MkxTableCell>{index + 1}</MkxTableCell>
                <MkxTableCell>{row.name}</MkxTableCell>
                <MkxTableCell>{row.email}</MkxTableCell>
                <MkxTableCell>{row.phone_no || '-'}</MkxTableCell>
                <MkxTableCell>{moment(row.date).format('DD/MM/YYYY')}</MkxTableCell>
                <MkxTableCell>{row.time}</MkxTableCell>
                <MkxTableCell>{row.content}</MkxTableCell>
                {/* <MkxTableCell>Rs 1000</MkxTableCell>
                <MkxTableCell>
                  <MkxButton variant="text" className="!bg-gray-200 ">
                    Paid
                  </MkxButton>
                </MkxTableCell> */}
                <MkxTableCell>{row.lawyer?.name}</MkxTableCell>

                <MkxTableCell>
                  {' '}
                  <Chip
                    label={row.status}
                    className="w-28"
                    // clickable={row.status === "Accepted" ? false : true}
                    color={
                      row.status === 'Pending'
                        ? 'primary'
                        : row.status === 'Approved'
                          ? 'success'
                          : 'error'
                    }
                  />
                </MkxTableCell>
              </MkxTableRow>
            ))}
          </MkxTableBody>
        </MkxTable>

        <MkxPagination data={data?.count} setPage={setPage} />
      </MkxTableContainer>
    </div>
  )
}

export default TotalAppointments
