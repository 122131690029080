import { API_URLs } from 'Configs/apis.config' // Importing API URLs from configuration
import axios from 'Configs/axios.config' // Importing Axios instance

/**
 * Function to authenticate user.
 * @param reqBody Optional request body containing user credentials.
 * @returns Promise that resolves to the authentication response data.
 * @throws Error if authentication fails.
 */
export const authenticateFn = async (reqBody?: any): Promise<any> => {
  try {
    const response = await axios.post(API_URLs.authentication, reqBody) // Sending POST request to authenticate user
    return response.data // Returning the authentication response data
  } catch (error) {
    console.error('Error authenticating user:', error) // Logging any errors that occur during authentication
    throw error // Throwing error for handling in upper layers
  }
}
