import React, { useState } from 'react'
import { FormHelperText, IconButton, TextField, TextFieldProps } from '@mui/material'
import { Search, Visibility, VisibilityOff } from '@mui/icons-material'
import classNames from 'classnames'

interface CustomInputProps extends Omit<TextFieldProps, 'onChange' | 'onBlur' | 'value'> {
  id: string
  formik?: {
    values: Record<string, any>
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
    errors: Record<string, string>
    touched: Record<string, boolean>
  }
  label?: string
  isRequired?: boolean
  text?: string
  paddingX?: number
  textAlign?: 'start' | 'end' | 'center' | 'justify' | 'left' | 'right'
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (evonChangeent: React.FocusEvent<HTMLInputElement>) => void
  value?: any
}

const CustomInput: React.FC<CustomInputProps> = ({
  type = '',
  value,
  onChange,
  label = '',
  className = '',
  id = '',
  formik,
  size = 'small',
  InputProps,
  onBlur,
  isRequired = false,
  text = 'base',
  paddingX = 3,
  textAlign = 'start',
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState<{ [key: string]: boolean }>({ [id]: false })
  const selectedValue = formik?.values[id] ?? value
  const handleChange = formik?.handleChange ?? onChange

  return (
    <div className="flex flex-col justify-center">
      {label && (
        <p className="m-1 font-medium whitespace-nowrap">
          {label}
          {isRequired && <span className="text-red-800">*</span>}
        </p>
      )}
      <TextField
        id={id}
        name={id}
        type={type !== 'password' ? type : isVisible[id] ? 'text' : 'password'}
        size={size}
        inputProps={{
          className:
            type === 'file'
              ? `!pt-1.5 !pl-1.5 !pb-3`
              : `!text-${text} text-${textAlign} !px-${paddingX}`,
        }}
        InputProps={{
          ...InputProps,
          ...(type === 'search' && {
            endAdornment: <Search className="text-black text-opacity-60" />,
          }),
          ...(type === 'password' && {
            endAdornment: (
              <IconButton size="small" onClick={() => setIsVisible({ [id]: !isVisible[id] })}>
                {isVisible[id] ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }),
        }}
        error={!!(formik?.errors?.[id] && formik?.touched?.[id])}
        onBlur={onBlur || formik?.handleBlur}
        value={selectedValue}
        onChange={handleChange}
        className={classNames(
          '!outline-none placeholder:!text-gray-100 !capitalize !bg-white !bg-opacity-20 !border-opacity-30 !border !border-white',
          className
        )}
        {...rest}
      />
      {formik?.touched?.[id] && formik?.errors?.[id] && (
        <FormHelperText className="!text-red-500 !mx-1">{formik?.errors?.[id]}</FormHelperText>
      )}
    </div>
  )
}

export default CustomInput
