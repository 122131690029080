import { PaletteMode, ThemeProvider, createTheme } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Layout from 'Layout'
import Authentication from 'Authentication'
import { routes } from 'Routes'
import { useLocalStorage } from 'Shared/MkxStorage'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const App = () => {
  const query = new QueryClient()
  const [mode = 'light', setMode] = useLocalStorage<PaletteMode>('mode', 'light')

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: mode === 'light' ? '#000081' : 'rgb(59 130 246)',
      },
    },
  })

  useEffect(() => {
    document.documentElement.classList.toggle('dark', mode === 'dark')
  }, [mode])

  return (
    <QueryClientProvider client={query}>
      <ThemeProvider theme={theme}>
        <Toaster
          position="top-center"
          toastOptions={{ className: '!border !border-solid !capitalize' }}
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Authentication />} />

            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout
                      mode={mode}
                      setMode={setMode}
                      component={route.element} 
                      navItem={route.name}
                    />
                  }
                />
              )
            })}
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
