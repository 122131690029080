import { useQuery } from '@tanstack/react-query'
import { getBlogsFn } from 'Services/Blog'
import { teamGetFn } from 'Services/Team'
import withAuthentication from 'Shared/Authentication'
import React from 'react'
import { useParams } from 'react-router-dom'

const ViewTeam: React.FC = () => {
  const { team_id } = useParams()
  const { data } = useQuery({
    queryKey: ['getBlogDetail', team_id],
    queryFn: () => teamGetFn({ team_id }),
    refetchOnWindowFocus: false,
  })
  const blog = data?.data
  return (
    <div className="flex flex-col gap-5 p-3 lg:px-[10%]">
      <p className="text-3xl lg:text-5xl font-bold lg:mx-auto">The Likhit De team</p>
      {blog?.map((items: any) => (
        <div key={items.id} className="flex flex-col gap-5 my-16">
          <div className="grid grid-cols-1 px-auto items-center justify-center gap-4 ">
            <img src={items.image} alt="" className="border-8 rounded-full size-48 mx-auto" />
            <div className="flex flex-col justify-center lg:px-10">
              <p className="text-3xl font-semibold text-green-500 mx-auto">{items.name}</p>
              <p className="text-2xl mx-auto">{items.role}</p>
              <p className="pt-5 lg:text-xl">{items.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ViewTeam
