import { MenuItem } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { planTypeCreateFn, planTypeGetFn, planTypeUpdateFn } from 'Services/PlanTypes'
import { MkxButton, MkxInput, MkxSelect } from 'Shared/Mkx'
import { ImagePicker } from 'Shared/useImageCrop/ImagePicker'
import { useFormik } from 'formik'
import React from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

const ManagePlanTypes: React.FC = () => {
  const { state } = useLocation()
  const isUpdate = Boolean(state)
  const navigate = useNavigate()
  console.log('State', state)
  const { data: planTypes } = useQuery({
    queryKey: ['planTypeGet', state],
    queryFn: () => planTypeGetFn({ plan_id: state }),
    refetchOnWindowFocus: false,
    enabled: isUpdate,
  })
  const typeData = planTypes?.data

  const { mutate: plansCreate, isPending: isCreating } = useMutation({
    mutationFn: planTypeCreateFn,
    onSuccess(res) {
      if (res?.response_code === 200) {
        toast.success(res.message)
        navigate('/planType')
      } else {
        toast.error(res.message)
      }
    },
  })
  const { mutate: plansUpdate, isPending: isUpdating } = useMutation({
    mutationFn: planTypeUpdateFn,
    onSuccess(res) {
      toast.success(res.message)
      navigate('/planType')
    },
  })

  const formik = useFormik({
    initialValues: {
      title: typeData?.title ?? '',
      type: typeData?.type ?? '',
      image: '',
      discription: typeData?.descriptions ?? '',
    },
    enableReinitialize: true,
    onSubmit(values) {
      const reqBody = new FormData()
      reqBody.append('title', values.title)
      reqBody.append('type', values.type)
      reqBody.append('image', values.image)
      reqBody.append('discription', values.discription)
      isUpdate && reqBody.append('plan_id', state)
      isUpdate ? plansUpdate(reqBody) : plansCreate(reqBody)
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5 p-3">
        <div className="grid grid-cols-1 gap-5">
          <MkxInput
            required
            id="title"
            name="title"
            label="Title"
            placeholder="Enter Title"
            value={formik.values.title}
            onChange={formik.handleChange}
          />
          {/* <MkxInput
            required
            id="type"
            name="type"
            label="Type"
            placeholder="Enter Type"
            value={formik.values.type}
            onChange={formik.handleChange}
          /> */}
          <MkxSelect
            required
            id="type"
            name="type"
            label="Type"
            placeholder="Enter Type"
            value={formik.values.type}
            onChange={formik.handleChange}
          >
            <MenuItem value="Subscription">Subscription</MenuItem>
            <MenuItem value="NFC_Card">NFC</MenuItem>
          </MkxSelect>
          <ImagePicker name="image" formik={formik} placeholder="Plan  Image" />

          <MkxInput
            rows={3}
            multiline
            name="discription"
            label="description"
            placeholder="Enter  Description"
            value={formik.values.discription}
            onChange={formik.handleChange}
          />
        </div>

        {/* <MkxEditor formik={formik} /> */}

        <div className="flex items-center justify-end gap-5">
          <MkxButton color="inherit" onClick={() => navigate(-1)}>
            Cancel
          </MkxButton>
          <MkxButton loading={isCreating || isUpdating} type="submit">
            Submit
          </MkxButton>
        </div>
      </form>
    </>
  )
}

export default ManagePlanTypes
